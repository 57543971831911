import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import { AuthProvider } from './auth/AuthProvider';
import { HeaderBar } from './components/core/HeaderBar/HeaderBar';
import { colors } from './components/stylings/colors';
import { AppRoutes } from './routes/routes';

const theme = createTheme({
  palette: {
    primary: {
      main: `${colors.primaryBlue}`
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <HeaderBar />
        <AppRoutes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
