import { Auth } from 'aws-amplify';

export interface FetchUserResponse {
  user: string;
  token: string;
}
export const fetchUser = async (): Promise<FetchUserResponse | Error> => {
  try {
    const cognitoResponse = await Auth.currentAuthenticatedUser();
    const user =
      cognitoResponse.signInUserSession.idToken.payload.email.replace(
        /@.*$/,
        ''
      );
    const token = cognitoResponse.signInUserSession.idToken.jwtToken;

    return { user, token };
  } catch (error) {
    return error as Error;
  }
};
