import { Navigate } from 'react-router-dom';

export const RequireAuth = ({ children }) => {
  // check if the token is valid
  const token = localStorage.getItem('jwtToken');

  if (!token) {
    return <Navigate to="/login" />;
  } else {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    if (decodedToken && decodedToken.exp) {
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      if (currentTime > expirationTime) {
        sessionStorage.setItem('loginPressed', 'false');
        return <Navigate to="/login" />;
      } else {
        return children;
      }
    }
  }
};
