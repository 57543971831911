import { AllFormFields } from '../../components/material-registration/types/formFields';
import { SubstanceCategoryKey } from '../../data/substances';

export type ParsedSubstance = AllFormFields & { id: string };

export const parseSubstanceFields = (substance: any): ParsedSubstance => {
  const { substanceCategory } = substance;
  const baseFormFields = {
    reagentName: substance?.substanceName,
    comments: substance?.comments,
    hazards: substance?.hazards
  };

  switch (substanceCategory) {
    case SubstanceCategoryKey.KIT:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.KIT,
        targetGene: substance?.targetGene,
        kit: substance?.kit,
        aliasName: substance?.aliasName,
        id: substance?.id
      };
    case SubstanceCategoryKey.PRIMER:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.PRIMER,
        targetGene: substance?.targetGene,
        aliasName: substance?.aliasName,
        species: substance?.species,
        sequence: substance?.squence,
        direction: substance?.direction,
        substanceState: substance?.substanceState,
        primerCategory: substance?.primerCategory,
        fluorescentDye: substance?.fluorescentDye,
        quencher: substance?.quencher,
        id: substance?.id
      };
    case SubstanceCategoryKey.PLASMID:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.PLASMID,
        targetGene: substance?.targetGene,
        aliasName: substance?.aliasName,
        vectorBuilderUrl: substance?.vectorBuilderUrl,
        bacterialResistance: substance?.bacterialResistance,
        selectionResistance: substance?.selectionResistance,
        promoter: substance?.promoter,
        substanceState: substance?.substanceState,
        id: substance?.id
      };
    case SubstanceCategoryKey.ANTIBODY:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.ANTIBODY,
        targetGene: substance?.targetGene,
        aliasName: substance?.aliasName,
        species: substance?.species,
        sequence: substance?.sequence,
        color: substance?.color,
        speciesRaised: substance?.speciesRaised,
        id: substance?.id
      };
    case SubstanceCategoryKey.RNA:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.RNA,
        targetGene: substance?.targetGene,
        aliasName: substance?.aliasName,
        sequence: substance?.sequence,
        ncbiSubstanceId: substance?.ncbiSubstanceId,
        entrezId: substance?.entrezId,
        sequenceId: substance?.sequenceId,
        cgRNAName: substance?.cgRNAName,
        endonuclease: substance?.endonuclease,
        promoter: substance?.promoter,
        koa: substance?.koa,
        id: substance?.id,
        rnaType: substance?.rnaType
      };
    case SubstanceCategoryKey.CHEMICAL_STOCK:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.CHEMICAL_STOCK,
        formula: substance?.formula,
        aliasName: substance?.aliasName,
        molWt: substance?.molWt,
        substanceState: substance?.substanceState,
        id: substance?.id
      };
    case SubstanceCategoryKey.COLOURED_PROBE:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.COLOURED_PROBE,
        targetGene: substance?.targetGene,
        color: substance?.color,
        id: substance?.id
      };
    default:
      return {
        ...baseFormFields,
        formType: SubstanceCategoryKey.COLOURED_PROBE,
        targetGene: substance?.targetGene,
        color: substance?.color,
        id: substance?.id
      };
  }
};
