import { Box, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SubstanceCategoryKey } from '../../../../data/substances';
import { COMMENTS_LABEL } from '../../../../i18n/trsl/batchRegistration';
import { NAME_LABEL } from '../../../../i18n/trsl/home';
import {
  ALIAS_LABEL,
  DIRECTION_LABEL,
  FLUORESCENT_DYE_LABEL,
  HAZARDS_LABEL,
  PRIMER_CATEGORY_LABEL,
  QUENCHER_LABEL,
  SEQUENCE_LABEL,
  SPECIES_LABEL,
  TARGET_GENE_LABEL
} from '../../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import { SubstanceFormFields } from '../../types/formFields';
import {
  PrimerCategoryValues,
  directionData,
  hazardsData,
  primerCategoryData,
  speciesData
} from '../formData';

interface PrimerFormProps {
  control: Control<SubstanceFormFields>;
  primerCategory: PrimerCategoryValues;
}

const PrimerForm: React.FC<PrimerFormProps> = ({
  control,
  primerCategory
}: PrimerFormProps) => (
  <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
    <Controller
      name={`${SubstanceCategoryKey.PRIMER}.reagentName`}
      control={control}
      rules={{
        required: REQUIRED_LABEL.en,
        pattern: {
          value: APHANUM_SPACE_DASH_DOT_REGEX,
          message: APHANUM_SPACE_DASH_DOT_LABEL.en
        }
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          label={NAME_LABEL.en}
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
          margin="normal"
        />
      )}
    />
    <Controller
      name={`${SubstanceCategoryKey.PRIMER}.targetGene`}
      control={control}
      rules={{
        required: REQUIRED_LABEL.en,
        pattern: {
          value: APHANUM_SPACE_DASH_REGEX,
          message: APHANUM_SPACE_DASH_LABEL.en
        }
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          label={TARGET_GENE_LABEL.en}
          margin="normal"
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
        />
      )}
    />
    <Dropdown
      control={control}
      inputLabel={HAZARDS_LABEL.en}
      controlName={`${SubstanceCategoryKey.PRIMER}.hazards`}
      data={hazardsData}
    />
    <Controller
      name={`${SubstanceCategoryKey.PRIMER}.aliasName`}
      control={control}
      rules={{
        pattern: {
          value: APHANUM_SPACE_DASH_REGEX,
          message: APHANUM_SPACE_DASH_LABEL.en
        }
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          label={ALIAS_LABEL.en}
          margin="normal"
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
        />
      )}
    />
    <Dropdown
      control={control}
      inputLabel={SPECIES_LABEL.en}
      rules={{ required: REQUIRED_LABEL.en }}
      controlName={`${SubstanceCategoryKey.PRIMER}.species`}
      data={speciesData}
    />
    <Controller
      name={`${SubstanceCategoryKey.PRIMER}.sequence`}
      control={control}
      rules={{
        pattern: {
          value: APHANUM_SPACE_DASH_REGEX,
          message: APHANUM_SPACE_DASH_LABEL.en
        }
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          label={SEQUENCE_LABEL.en}
          margin="normal"
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
        />
      )}
    />
    <Dropdown
      control={control}
      inputLabel={DIRECTION_LABEL.en}
      controlName={`${SubstanceCategoryKey.PRIMER}.direction`}
      data={directionData}
    />
    <Dropdown
      control={control}
      inputLabel={PRIMER_CATEGORY_LABEL.en}
      rules={{ required: REQUIRED_LABEL.en }}
      controlName={`${SubstanceCategoryKey.PRIMER}.primerCategory`}
      data={primerCategoryData}
    />
    {primerCategory === PrimerCategoryValues.TAQMAN && (
      <>
        <Controller
          name={`${SubstanceCategoryKey.PRIMER}.fluorescentDye`}
          control={control}
          rules={{
            pattern: {
              value: APHANUM_SPACE_DASH_REGEX,
              message: APHANUM_SPACE_DASH_LABEL.en
            }
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={FLUORESCENT_DYE_LABEL.en}
              margin="normal"
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
        <Controller
          name={`${SubstanceCategoryKey.PRIMER}.quencher`}
          control={control}
          rules={{
            pattern: {
              value: APHANUM_SPACE_DASH_REGEX,
              message: APHANUM_SPACE_DASH_LABEL.en
            }
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={QUENCHER_LABEL.en}
              margin="normal"
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
      </>
    )}
    <Controller
      name={`${SubstanceCategoryKey.PRIMER}.comments`}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          label={COMMENTS_LABEL.en}
          {...field}
          margin="normal"
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
        />
      )}
    />
  </Box>
);

export default PrimerForm;
