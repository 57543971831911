import { ADD_FORMULATION_LABEL } from '../i18n/trsl/formulation';
import { ADD_BATCH_TITLE, ADD_SUBSTANCE_TITLE } from '../i18n/trsl/home';

export const FORMULATION_BATCH_FORM_STEPS = [
  ADD_FORMULATION_LABEL.en,
  ADD_BATCH_TITLE.en
];

export const SUBSTANCE_BATCH_FORM_STEPS = [
  ADD_SUBSTANCE_TITLE.en,
  ADD_BATCH_TITLE.en
];
