import { SubstanceCategoryKey } from '../../../../data/substances';
import {
  AllFormFields,
  AntibodyFields,
  ChemicalStockFields,
  ColouredProbeFields,
  KitFields,
  PlasmidFields,
  PrimerFields,
  RNAFields,
  SubstanceFormFields
} from '../../types/formFields';
import {
  DirectionValues,
  HazardsValues,
  PrimerCategoryValues,
  SpeciesValues,
  SubstanceStateValues
} from '../formData';

const baseFormFields = {
  reagentName: '',
  comments: '',
  hazards: HazardsValues.NON_HAZARD
};

export const getDefaultSubstanceFields = (
  formCategory: SubstanceCategoryKey
): AllFormFields => {
  switch (formCategory) {
    case SubstanceCategoryKey.KIT:
      return {
        ...baseFormFields,
        targetGene: '',
        kit: '',
        aliasName: '',
        formType: SubstanceCategoryKey.KIT
      };
    case SubstanceCategoryKey.PRIMER:
      return {
        ...baseFormFields,
        targetGene: '',
        aliasName: '',
        species: SpeciesValues.HUMAN,
        sequence: '',
        direction: DirectionValues.FORWARD,
        substanceState: '',
        primerCategory: PrimerCategoryValues.PRIMER,
        fluorescentDye: '',
        quencher: '',
        formType: SubstanceCategoryKey.PRIMER
      };
    case SubstanceCategoryKey.PLASMID:
      return {
        ...baseFormFields,
        targetGene: '',
        aliasName: '',
        vectorBuilderUrl: '',
        bacterialResistance: '',
        selectionResistance: '',
        promoter: '',
        substanceState: SubstanceStateValues.GLYCEROL,
        formType: SubstanceCategoryKey.PLASMID
      };
    case SubstanceCategoryKey.ANTIBODY:
      return {
        ...baseFormFields,
        targetGene: '',
        aliasName: '',
        species: SpeciesValues.HUMAN,
        sequence: '',
        color: '',
        speciesRaised: SpeciesValues.HUMAN,
        formType: SubstanceCategoryKey.ANTIBODY
      };
    case SubstanceCategoryKey.RNA:
      return {
        ...baseFormFields,
        targetGene: '',
        aliasName: '',
        sequence: '',
        ncbiSubstanceId: '',
        entrezId: '',
        sequenceId: '',
        cgRNAName: '',
        endonuclease: '',
        promoter: '',
        koa: '',
        rnaType: '',
        formType: SubstanceCategoryKey.RNA
      };
    case SubstanceCategoryKey.CHEMICAL_STOCK:
      return {
        ...baseFormFields,
        formula: '',
        aliasName: '',
        molWt: '',
        substanceState: '',
        formType: SubstanceCategoryKey.CHEMICAL_STOCK
      };
    case SubstanceCategoryKey.COLOURED_PROBE:
      return {
        ...baseFormFields,
        targetGene: '',
        color: '',
        formType: SubstanceCategoryKey.COLOURED_PROBE
      };
    default:
      return {
        ...baseFormFields,
        targetGene: '',
        kit: '',
        aliasName: '',
        formType: SubstanceCategoryKey.KIT
      };
  }
};

export const getDefaultSubstanceForm = (): SubstanceFormFields => ({
  category: SubstanceCategoryKey.KIT,
  [SubstanceCategoryKey.KIT]: getDefaultSubstanceFields(
    SubstanceCategoryKey.KIT
  ) as KitFields,
  [SubstanceCategoryKey.PRIMER]: getDefaultSubstanceFields(
    SubstanceCategoryKey.PRIMER
  ) as PrimerFields,
  [SubstanceCategoryKey.PLASMID]: getDefaultSubstanceFields(
    SubstanceCategoryKey.PLASMID
  ) as PlasmidFields,
  [SubstanceCategoryKey.ANTIBODY]: getDefaultSubstanceFields(
    SubstanceCategoryKey.ANTIBODY
  ) as AntibodyFields,
  [SubstanceCategoryKey.RNA]: getDefaultSubstanceFields(
    SubstanceCategoryKey.RNA
  ) as RNAFields,
  [SubstanceCategoryKey.COLOURED_PROBE]: getDefaultSubstanceFields(
    SubstanceCategoryKey.COLOURED_PROBE
  ) as ColouredProbeFields,
  [SubstanceCategoryKey.CHEMICAL_STOCK]: getDefaultSubstanceFields(
    SubstanceCategoryKey.CHEMICAL_STOCK
  ) as ChemicalStockFields
});
