import { Alert, Box, Button, Stack } from '@mui/material';
import { ParsedSubstance } from '../../../../apis/utils/parseSubstanceFields';
import trsl from '../../../../i18n/index';

export type DuplicationWithData = {
  substance: ParsedSubstance | Record<string, string>;
  duplication: boolean;
};

export type DuplicateSubstaceAlertProps = {
  duplicateSubstance: DuplicationWithData;
  handleSkip: () => void;
};

const DuplicateSubstanceAlert = ({
  duplicateSubstance,
  handleSkip
}: DuplicateSubstaceAlertProps) => {
  const { CHANGE_NAME_TEXT, PROCEED_LABEL, SUBSTANCE_ALREADY_EXIST } =
    trsl.materialRegistration;
  const { duplication, substance } = duplicateSubstance;

  if (!duplication) return null;
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Stack direction="row">
        <Alert severity="info" sx={{ display: 'flex', alignItems: 'center' }}>
          {SUBSTANCE_ALREADY_EXIST.en}
          <Box ml={2}>
            {Object.entries(substance).map(([fieldType, val]) => (
              <div key={fieldType}>
                {fieldType}: {JSON.stringify(val)}
              </div>
            ))}
          </Box>
          {CHANGE_NAME_TEXT.en}
          <Button
            onClick={handleSkip}
            size="small"
            variant="outlined"
            sx={{ ml: 2 }}
          >
            {PROCEED_LABEL.en}
          </Button>
        </Alert>
      </Stack>
    </Box>
  );
};

export default DuplicateSubstanceAlert;
