import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { colors } from '../../stylings/colors';

interface LoadingSpinnerProps {
  message: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message }) => (
  <Grid>
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flex="wrap"
        flexDirection="column"
        my={13}
      >
        <CircularProgress size={96} sx={{ color: colors.primaryBlueActive }} />
        <Typography variant="h6" sx={{ marginTop: 8 }}>
          {message}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export default LoadingSpinner;
