import axios from 'axios';
import { PrimerCategoryValues } from '../../components/material-registration/forms/formData';
import { AllFormFields } from '../../components/material-registration/types/formFields';
import { SUBSTANCE_PATH, createApiURL } from '../../configs/envConfig';
import { SubstanceCategoryKey } from '../../data/substances';
import { ApiResponse, ApiResponseData } from '../api';
import { CodeWithResultSubstanceRegister } from './substanceTypes';

export type RegisterSubstanceArgs = {
  category: string;
  formData: AllFormFields;
};

export const registerSubstance = async ({
  category,
  formData
}: RegisterSubstanceArgs): Promise<
  ApiResponseData<CodeWithResultSubstanceRegister>
> => {
  const SUBSTANCE_API = createApiURL({
    apiPath: SUBSTANCE_PATH
  });

  const data = {
    ...clearFields(category, formData),
    substanceName: formData.reagentName,
    substanceCategory: category,
    registeredBy: localStorage.getItem('username')
  };

  /* eslint-disable-next-line no-unused-vars */
  const { reagentName, formType, ...dataToSend } = data;

  const payload = JSON.stringify({ substance: dataToSend });
  const token = localStorage.getItem('jwtToken');

  try {
    if (!token) {
      throw Error('User is not logged in');
    }
    const response: ApiResponse<CodeWithResultSubstanceRegister> =
      await axios.post(SUBSTANCE_API, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    return response.data;
  } catch (error) {
    throw Error('Error' + error);
  }
};

const clearFields = (category: string, formData: AllFormFields) => {
  if (
    category === SubstanceCategoryKey.PRIMER &&
    formData.formType === SubstanceCategoryKey.PRIMER &&
    formData.primerCategory !== PrimerCategoryValues.TAQMAN
  ) {
    return { ...formData, fluorescentDye: '', quencher: '' };
  }
  return formData;
};
