import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { BatchDataResponse } from '../../../../apis/batch/batchTypes';
import {
  CATALOG_NUMBER,
  CLASS_LABEL,
  COMMENTS_LABEL,
  CREATED_LABEL,
  EXPIRY_LABEL,
  FLAG_LABEL,
  ID,
  INVALID_DATE,
  SUBSTANCE_ID_LABEL,
  SUPPLIER,
  SUPPLIER_BATCH_NUMBER_LABEL,
  UPDATED_LABEL,
  VENDOR_ID_LABEL
} from '../../../../i18n/trsl/batchRegistration';
import { REGISTERED_BY_LABEL } from '../../../../i18n/trsl/home';

import { NA_LABEL } from '../../../../i18n/trsl/materialRegistration';
import { getBaseURL, textDisplayOrNA } from '../../../../utils/utils';
import { colors } from '../../../stylings/colors';

interface BatchTableProps {
  batches: BatchDataResponse[];
}

const BatchTable: React.FC<BatchTableProps> = ({ batches }) => {
  const baseUrl = getBaseURL();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="batch table">
        <TableHead sx={{ backgroundColor: colors.veryLightGray }}>
          <TableRow>
            <TableCell />
            <TableCell>{ID.en}</TableCell>
            <TableCell>{SUPPLIER.en}</TableCell>
            <TableCell>{CATALOG_NUMBER.en}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {batches.map((batch, idx) => (
            <BatchRow
              key={batch.id}
              idx={idx}
              batch={batch}
              baseUrl={baseUrl}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface BatchRowProps {
  batch: BatchDataResponse;
  idx: number;
  baseUrl?: string;
}

const BatchRow: React.FC<BatchRowProps> = ({ batch, idx, baseUrl }) => {
  const [open, setOpen] = useState(idx === 0);
  let expirationDate;
  // Check if batch.expirationDate is valid before trying to create a Date object
  if (batch?.expirationDate && Date.parse(batch.expirationDate)) {
    const dateObject = new Date(batch.expirationDate);
    expirationDate = `${dateObject.toLocaleDateString()}`;
  } else {
    expirationDate = INVALID_DATE.en; // Handle invalid date format
  }
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{textDisplayOrNA(batch?.id)}</TableCell>
        <TableCell>{textDisplayOrNA(batch?.supplier)}</TableCell>
        <TableCell>{textDisplayOrNA(batch?.catalogueNumber)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${SUPPLIER_BATCH_NUMBER_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.supplierBatchNumber)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${SUBSTANCE_ID_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.substanceId) === NA_LABEL.en ? (
                  NA_LABEL.en
                ) : (
                  <>
                    <Typography
                      component="a"
                      href={`${baseUrl}/substances/${batch?.substanceId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'blue' }}
                    >
                      {batch?.substanceId}
                    </Typography>
                  </>
                )}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${CREATED_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.created)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${VENDOR_ID_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.vendorSuppliedId)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${CLASS_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.class)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${UPDATED_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.updated)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${FLAG_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.flag)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${REGISTERED_BY_LABEL.en}: `}
                </span>
                {textDisplayOrNA(batch?.registeredBy)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${EXPIRY_LABEL.en}: `}</span>
                {textDisplayOrNA(expirationDate)}
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                <span style={{ fontWeight: 'bold' }}>
                  {`${COMMENTS_LABEL.en} `}
                </span>
                {textDisplayOrNA(batch?.comments)}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default BatchTable;
