import { i18n } from '../i18nType';

export const ADD_SIRNA_LABEL: i18n = { en: 'Add siRNA' };
export const ADD_FORMULATION_LABEL: i18n = { en: 'Add Formulation' };

export const INPUT_LABEL = { en: 'List of Sequence ID and Sequence' };

export const INPUT_DESCRIPTION = {
  en: 'Use a new line to input a new pair. Separate each Sequence ID and sequence with a space.'
};
