import { Amplify } from 'aws-amplify';
import {
  DEV_HOST,
  DEV_URL,
  DOMAIN_DEV,
  DOMAIN_PROD,
  LOCAL_HOST_URL,
  PRODUCTION_HOST,
  PRODUCTION_URL,
  USER_POOL_ID_DEV,
  USER_POOL_ID_PROD,
  USER_POOL_WEB_CLIENT_ID_DEV,
  USER_POOL_WEB_CLIENT_ID_PROD
} from './configVars';

export const configureAmplify = () => {
  const isProduction = window?.location?.host === PRODUCTION_HOST;
  const isDev = window?.location?.host === DEV_HOST;

  let redirectSignIn = null;
  let redirectSignOut = null;

  if (isProduction) {
    redirectSignIn = PRODUCTION_URL;
    redirectSignOut = PRODUCTION_URL;
  } else if (isDev) {
    redirectSignIn = DEV_URL;
    redirectSignOut = DEV_URL;
  } else {
    redirectSignIn = LOCAL_HOST_URL;
    redirectSignOut = LOCAL_HOST_URL;
  }

  const userPoolId = isProduction ? USER_POOL_ID_PROD : USER_POOL_ID_DEV;
  const userPoolWebClientId = isProduction
    ? USER_POOL_WEB_CLIENT_ID_PROD
    : USER_POOL_WEB_CLIENT_ID_DEV;
  const domain = isProduction ? DOMAIN_PROD : DOMAIN_DEV;

  const amplifyConfig = {
    Auth: {
      region: 'eu-central-1',
      userPoolId: userPoolId,
      userPoolWebClientId: userPoolWebClientId,
      oauth: {
        domain: domain,
        scope: ['openid'],
        redirectSignIn,
        redirectSignOut,
        responseType: 'code'
      },
      customProvider: 'AzureAD',
      storage: sessionStorage,
      federatedSignIn: { customProvider: 'AzureAD' }
    }
  };

  Amplify.configure(amplifyConfig);
};
