import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

import { REQUIRED_LABEL } from '../../../i18n/trsl/validation';
import { DataSelector } from '../../material-registration/forms/formData';
import { colors } from '../../stylings/colors';

interface DropdownProps {
  control: Control<any>;
  inputLabel: string;
  controlName: string;
  data: DataSelector[];
  variant?: 'filled' | 'outlined' | 'standard';
  fullWidth?: boolean;
  rules?: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;

  helperText?: string;
}

const Dropdown = ({
  control,
  inputLabel,
  controlName,
  data,
  variant,
  rules,
  helperText
}: DropdownProps) => (
  <Controller
    name={controlName}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <FormControl
        sx={{
          mt: 2,
          pt: 1,
          minWidth: 150
        }}
        size="medium"
        variant={variant}
        error={!!fieldState?.error}
      >
        <InputLabel>{inputLabel}</InputLabel>
        <Select {...field}>
          {data.map(hazards => (
            <MenuItem key={hazards.label} value={hazards.value}>
              {hazards.label}
            </MenuItem>
          ))}
        </Select>
        {!!fieldState?.error && (
          <FormHelperText sx={{ color: colors.errorRed }}>
            {REQUIRED_LABEL.en}
          </FormHelperText>
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )}
  />
);

export default Dropdown;
