import { Box, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SubstanceCategoryKey } from '../../../../data/substances';
import { COMMENTS_LABEL } from '../../../../i18n/trsl/batchRegistration';
import { NAME_LABEL } from '../../../../i18n/trsl/home';
import {
  ALIAS_LABEL,
  FORMULA_LABEL,
  HAZARDS_LABEL,
  MOLWT_LABEL,
  SUBSTANCE_STATE_LABEL
} from '../../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import { SubstanceFormFields } from '../../types/formFields';
import { hazardsData, substanceStateData } from '../formData';

interface ChemicalStockFormProps {
  control: Control<SubstanceFormFields>;
}

const ChemicalStockForm: React.FC<ChemicalStockFormProps> = ({
  control
}: ChemicalStockFormProps) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name={`${SubstanceCategoryKey.CHEMICAL_STOCK}.reagentName`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={NAME_LABEL.en}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.CHEMICAL_STOCK}.formula`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={FORMULA_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={HAZARDS_LABEL.en}
        controlName={`${SubstanceCategoryKey.CHEMICAL_STOCK}.hazards`}
        data={hazardsData}
      />
      <Controller
        name={`${SubstanceCategoryKey.CHEMICAL_STOCK}.aliasName`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ALIAS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.CHEMICAL_STOCK}.molWt`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={MOLWT_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        rules={{ required: REQUIRED_LABEL.en }}
        inputLabel={SUBSTANCE_STATE_LABEL.en}
        controlName={`${SubstanceCategoryKey.CHEMICAL_STOCK}.substanceState`}
        data={substanceStateData}
      />
      <Controller
        name={`${SubstanceCategoryKey.CHEMICAL_STOCK}.comments`}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label={COMMENTS_LABEL.en}
            {...field}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default ChemicalStockForm;
