import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { BatchDataResponse } from '../../../../apis/batch/batchTypes';
import { BATCHES_INFORMATION } from '../../../../i18n/trsl/batchRegistration';
import BatchTable from './BatchTable';

interface BatchesListProps {
  open: boolean;
  onClose: () => void;
  closeDialog: () => void;
  batches: BatchDataResponse[];
}

const BatchesList: React.FC<BatchesListProps> = ({
  open,
  onClose,
  batches,
  closeDialog
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          minHeight: '60vh',
          padding: 20
        }
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <DialogTitle>{BATCHES_INFORMATION.en}</DialogTitle>
        <IconButton
          style={{ marginRight: '16px', marginTop: '8px', height: '40px' }}
          edge="end"
          color="inherit"
          onClick={closeDialog}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <BatchTable batches={batches} />
      </DialogContent>
    </Dialog>
  );
};

export default BatchesList;
