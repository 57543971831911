import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSubstanceFields } from '../../../apis/substance/getSubstanceFields';
import { SubstanceDataResponse } from '../../../apis/substance/substanceTypes';
import { SUBSTANCE_DETAILS_TITLE } from '../../../i18n/trsl/materialRegistration';
import { SubstanceTableWithActions } from '../../material-registration/SubstanceTableWithActions/SubstanceTableWithActions';

export const SubstanceDetails = () => {
  const { substanceId } = useParams();

  const [substanceDetails, setSubstanceDetails] =
    useState<SubstanceDataResponse[]>();

  useEffect(() => {
    const getSubstance = async () => {
      if (substanceId) {
        const substanceData = await getSubstanceFields({
          substanceFields: [{ value: substanceId, field: 'id' }]
        });
        setSubstanceDetails(substanceData);
      }
    };
    getSubstance();
  }, [substanceId]);

  return (
    <Box m={4}>
      <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
        {SUBSTANCE_DETAILS_TITLE.en}: {substanceId}
      </Typography>
      <SubstanceTableWithActions
        substanceDetails={substanceDetails ?? []}
        defaultExpanded
        hidePagination
      />
    </Box>
  );
};
