import {
  FormulationBatchFormFields,
  FormulationSirnaFormFields
} from '../../types/formulationFields';
import { HazardsValues } from '../formData';

export const getDefaultSirnaFormulationFields =
  (): FormulationSirnaFormFields => ({
    formulationType: '',
    substanceName: '',
    targetGene: '',
    substanceComponents: [],
    hazards: HazardsValues.NON_HAZARD,
    aliasName: '',
    comments: ''
  });

export const getDefaultBatchFormulationFields =
  (): FormulationBatchFormFields => ({
    substanceId: '',
    expirationDate: '',
    supplierBatchNumber: '',
    catalogueNumber: '',
    vendorSuppliedId: '',
    supplier: '',
    flag: '',
    comments: ''
  });
