import { Box, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SubstanceCategoryKey } from '../../../../data/substances';
import { COMMENTS_LABEL } from '../../../../i18n/trsl/batchRegistration';
import { COLOR_LABEL, NAME_LABEL } from '../../../../i18n/trsl/home';
import {
  ALIAS_LABEL,
  HAZARDS_LABEL,
  SEQUENCE_LABEL,
  SPECIES_LABEL,
  SPECIES_RAISED_LABEL,
  TARGET_GENE_LABEL
} from '../../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import { SubstanceFormFields } from '../../types/formFields';
import { hazardsData, speciesData, speciesRaisedData } from '../formData';

interface AntibodiesFormProps {
  control: Control<SubstanceFormFields>;
}

const AntibodiesForm: React.FC<AntibodiesFormProps> = ({
  control
}: AntibodiesFormProps) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name={`${SubstanceCategoryKey.ANTIBODY}.reagentName`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={NAME_LABEL.en}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.ANTIBODY}.targetGene`}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={TARGET_GENE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={HAZARDS_LABEL.en}
        controlName={`${SubstanceCategoryKey.ANTIBODY}.hazards`}
        data={hazardsData}
      />
      <Controller
        name={`${SubstanceCategoryKey.ANTIBODY}.aliasName`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ALIAS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={SPECIES_LABEL.en}
        rules={{ required: REQUIRED_LABEL.en }}
        controlName={`${SubstanceCategoryKey.ANTIBODY}.species`}
        data={speciesData}
      />
      <Controller
        name={`${SubstanceCategoryKey.ANTIBODY}.sequence`}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={SEQUENCE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.ANTIBODY}.color`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={COLOR_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={SPECIES_RAISED_LABEL.en}
        rules={{ required: REQUIRED_LABEL.en }}
        controlName={`${SubstanceCategoryKey.ANTIBODY}.speciesRaised`}
        data={speciesRaisedData}
      />
      <Controller
        name={`${SubstanceCategoryKey.ANTIBODY}.comments`}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label={COMMENTS_LABEL.en}
            {...field}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default AntibodiesForm;
