import axios from 'axios';
import { createApiURL } from '../../configs/envConfig';
import { BATCH } from '../../routes/consts';
import {
  CodeWithResultSearchBatch,
  SearchResultField
} from '../all/searchResultsTypes';
import { ApiResponse, ResponseType } from '../api';
import { BatchDataResponse } from '../batch/batchTypes';
import { buildQueryString } from '../utils/buildQueryString';

export type GetSubstanceFieldsArgs = {
  batchFields: SearchResultField<keyof BatchDataResponse>[];
};

export const getBatchFields = async ({
  batchFields
}: GetSubstanceFieldsArgs): Promise<BatchDataResponse[]> => {
  // Build string using fields
  const queryString = buildQueryString<keyof BatchDataResponse>(batchFields);

  const GET_SUBSTANCE_FIELDS_API = createApiURL({
    apiPath: `${BATCH}?${queryString}`
  });

  const token = localStorage.getItem('jwtToken');

  try {
    if (!token) {
      throw Error('User is not logged in');
    }
    const response: ApiResponse<CodeWithResultSearchBatch> = await axios.get(
      GET_SUBSTANCE_FIELDS_API,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.responseType === ResponseType.SUCCESS) {
      return response.data?.result;
    }
    return [];
  } catch (error) {
    throw Error('Error' + error);
  }
};
