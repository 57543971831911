import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getAllSubstances } from '../../../apis/substance/getAllSubstances';
import { SubstanceDataResponse } from '../../../apis/substance/substanceTypes';
import { lowercaseObjValues } from '../../../utils/utils';
import { SubstanceTableWithActions } from '../../material-registration/SubstanceTableWithActions/SubstanceTableWithActions';
import SearchWrapperBar from './SearchBar/SearchWrapperBar';

const SearchData = () => {
  const [allResults, setAllResults] = useState<
    SubstanceDataResponse[] | undefined
  >(undefined);
  const [filterText, setFilterText] = useState('');
  const [filterFields, setFilterFields] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const allSubstances = await getAllSubstances();
      setAllResults(allSubstances);
    };
    fetchData();
  }, []);

  const filteredResults = useMemo(() => {
    if (allResults !== undefined) {
      // Filter by simple search
      if (filterText.length > 0) {
        return allResults.filter(result =>
          JSON.stringify(result)
            .toLowerCase()
            .includes(filterText.toLowerCase())
        );
      }
      // Filter by advanced search
      if (Object.values(filterFields).length > 0) {
        const lowercaseFilters = lowercaseObjValues(filterFields);
        return allResults.filter(
          result =>
            _.isEqual(
              lowercaseFilters,
              _.pick(lowercaseObjValues(result), _.keys(lowercaseFilters))
            ) ||
            result?.batches?.some(batch =>
              _.isEqual(
                lowercaseFilters,
                _.pick(lowercaseObjValues(batch), _.keys(lowercaseFilters))
              )
            )
        );
      }
    }
    return allResults;
  }, [allResults, filterText, filterFields]);

  return (
    <>
      <SearchWrapperBar
        handleFilterItem={setFilterText}
        handleFilterFields={setFilterFields}
      />
      <SubstanceTableWithActions substanceDetails={filteredResults} />
    </>
  );
};

export default SearchData;
