import { i18n } from '../i18nType';

export const REQUIRED_LABEL: i18n = { en: 'Required field' };

export const APHANUM_SPACE_DASH_LABEL: i18n = {
  en: 'Only alphanumeric characters, spaces and dashes are allowed'
};

export const APHANUM_SPACE_DASH_DOT_LABEL: i18n = {
  en: 'Only alphanumeric characters, spaces, dots, % and dashes are allowed'
};

export const MULTILINE_ERROR = {
  en: 'Each line should have 2 entries. Please check your list with data.'
};
