export const SUBSTANCE_TYPES = 'substancetypes';
export const HOME_PAGE = 'home';
export const REAGENT = 'reagent';
export const BATCH = 'batch';
export const INFO = 'info';
export const SUBSTANCE = 'substance';
export const ID = 'id';
export const SEARCH = 'search';
export const FORMULATION = 'formulation';
export const BULK_UPLOAD = 'bulkupload';
export const LOGIN = 'login';
export const SUBSTANCES = 'substances';
export const SUBSTANCE_ID = 'substanceId';
export const BATCHES = 'batches';
export const BATCH_ID = 'batchId';
