import React, { ReactNode, createContext, useState } from 'react';

type User = string;
type Token = string;

interface AuthContextProps {
  user: User | null;
  /* eslint-disable no-unused-vars */
  login: (user: User, token: Token) => void;
  /* eslint-enable no-unused-vars */
  logout: () => void;
  token: Token | null;
}

const AuthContext = createContext<AuthContextProps | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<Token | null>(null);

  const login = (user: User, token: Token) => {
    setUser(user);
    setToken(token);
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
