export const PRODUCTION_HOST = 'reagent-registration-prod.nnrco.net';
export const PRODUCTION_URL = 'https://reagent-registration-prod.nnrco.net/';
export const DEV_HOST =
  'reagent-test.nnrcoreagent-dev.corp.aws.novonordisk.com';
export const DEV_URL =
  'https://reagent-test.nnrcoreagent-dev.corp.aws.novonordisk.com/';

export const API_TEST_URL =
  'https://ee0hjlqix7.execute-api.eu-central-1.amazonaws.com';
export const API_PROD_URL =
  'https://8v26os7kfg.execute-api.eu-central-1.amazonaws.com';

export const USER_POOL_ID_PROD = 'eu-central-1_S1PZ0AKaC';
export const USER_POOL_ID_DEV = 'eu-central-1_idAySb0Qh';

export const USER_POOL_WEB_CLIENT_ID_PROD = '5cuqc4f5m1fs8ujf39veqvf35b';
export const USER_POOL_WEB_CLIENT_ID_DEV = '5amua46o4uoda8cbr34da9rne8';

export const DOMAIN_PROD =
  'prd-reagent-app.auth.eu-central-1.amazoncognito.com';
export const DOMAIN_DEV = 'dev-reagent.auth.eu-central-1.amazoncognito.com';
export const LOCAL_HOST_URL = 'http://localhost:3000';
