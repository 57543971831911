import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { v4 as uuidv4 } from 'uuid';

interface SelectWithChipsProps {
  options: string[];
  value: string[];
  nonEditable?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
  // eslint-disable-next-line no-unused-vars
  onChange: (data: string[]) => void;
}

const SelectWithChips = ({
  options,
  value,
  nonEditable,
  fullWidth,
  sx,
  onChange
}: SelectWithChipsProps) => {
  return (
    <Select
      id="select-multiple-chip"
      multiple
      fullWidth={fullWidth}
      value={value}
      placeholder="Select options"
      onChange={event => onChange(event?.target?.value as string[])}
      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
      renderValue={selected => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map(value => (
            <Chip key={`${value}-${uuidv4()}`} label={value} />
          ))}
        </Box>
      )}
      sx={{
        ...sx,
        '& .MuiSelect-icon': {
          display: nonEditable ? 'none' : 'block'
        },
        minWidth: 200,
        pointerEvents: nonEditable ? 'none' : 'auto'
      }}
    >
      {options?.map(option => (
        <MenuItem key={`${option}-${uuidv4()}`} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectWithChips;
