import AddNewBatch from '../assets/images/AddNewBatch.png';
import BulkUploadDisabled from '../assets/images/BulkUploadDisabled.png';
import CreateFormulation from '../assets/images/CreateFormulation.png';
import RegisterMaterial from '../assets/images/RegisterMaterial.png';
import trsl from '../i18n/index';
import { BATCH_REGISTRATION_TITLE } from '../i18n/trsl/home';

export type LabActivity = {
  [key: string]: {
    title: string;
    description: string;
    buttonTitle: string;
    image: string;
    status: 'disabled' | 'enabled';
  };
};

const {
  MATERIAL_REGISTRATION_BUTTON,
  MATERIAL_REGISTRATION_DESCRIPTION,
  MATERIAL_REGISTRATION_TITLE,
  BATCH_REGISTRATION_BUTTON,
  BATCH_REGISTRATION_DESCRIPTION,
  NEW_FORMULATION_BUTTON,
  NEW_FORMULATION_DESCRIPTION,
  NEW_FORMULATION_TITLE,
  BULK_UPLOAD_BUTTON,
  BULK_UPLOAD_DESCRIPTION,
  BULK_UPLOAD_TITLE
} = trsl.home;

export enum LabActivityKey {
  /* eslint-disable no-unused-vars */
  MATERIAL_REGISTRATION = 'materialRegistration',
  BATCH_REGISTRATION = 'batchRegistration',
  SIRNA_FORMULATION = 'sirnaFormulation',
  BATCH_FORMULATION = 'batchFormulation',
  BULK_UPLOAD = 'bulkUpload'
  /* eslint-enable no-unused-vars */
}

export const labActivities: LabActivity = {
  [LabActivityKey.MATERIAL_REGISTRATION]: {
    title: MATERIAL_REGISTRATION_TITLE.en,
    description: MATERIAL_REGISTRATION_DESCRIPTION.en,
    buttonTitle: MATERIAL_REGISTRATION_BUTTON.en,
    image: RegisterMaterial,
    status: 'enabled'
  },
  [LabActivityKey.BATCH_REGISTRATION]: {
    title: BATCH_REGISTRATION_TITLE.en,
    description: BATCH_REGISTRATION_DESCRIPTION.en,
    buttonTitle: BATCH_REGISTRATION_BUTTON.en,
    image: AddNewBatch,
    status: 'enabled'
  },
  [LabActivityKey.SIRNA_FORMULATION]: {
    title: NEW_FORMULATION_TITLE.en,
    description: NEW_FORMULATION_DESCRIPTION.en,
    buttonTitle: NEW_FORMULATION_BUTTON.en,
    image: CreateFormulation,
    status: 'enabled'
  },
  [LabActivityKey.BULK_UPLOAD]: {
    title: BULK_UPLOAD_TITLE.en,
    description: BULK_UPLOAD_DESCRIPTION.en,
    buttonTitle: BULK_UPLOAD_BUTTON.en,
    image: BulkUploadDisabled,
    status: 'disabled'
  }
};
