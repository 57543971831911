import axios from 'axios';
import { FormulationSirnaFormFields } from '../../components/material-registration/types/formulationFields';
import { FORMULATION_PATH, createApiURL } from '../../configs/envConfig';
import { ApiResponse, ApiResponseData } from '../api';
import { CodeWithFormulationResult } from './formulationTypes';

export type RegisterSubstanceArgs = {
  formData: FormulationSirnaFormFields;
};

export const registerFormulation = async ({
  formData
}: RegisterSubstanceArgs): Promise<
  ApiResponseData<CodeWithFormulationResult>
> => {
  const FORMULATION_API = createApiURL({
    apiPath: FORMULATION_PATH
  });

  const data = {
    ...formData,
    substanceCategory: 'siRNA',
    registeredBy: localStorage.getItem('username')
  };

  const payload = JSON.stringify({ formulation: data });
  const token = localStorage.getItem('jwtToken');

  try {
    if (!token) {
      throw Error('User is not logged in');
    }
    const response: ApiResponse<CodeWithFormulationResult> = await axios.post(
      FORMULATION_API,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw Error('Error' + error);
  }
};

export type FormulationDataPayload = FormulationSirnaFormFields & {
  substanceCategory: string;
  registeredBy: string;
};
