import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ApiResponseData,
  ResponseType,
  SuccessResponseCode
} from '../../apis/api';
import { registerBatch } from '../../apis/batch/batch';
import { registerSubstance } from '../../apis/substance/registerSubstance';
import { CodeWithResultSubstanceRegister } from '../../apis/substance/substanceTypes';
import { parseBatchFields } from '../../apis/utils/parseBatchFields';
import {
  ParsedSubstance,
  parseSubstanceFields
} from '../../apis/utils/parseSubstanceFields';
import { SUBSTANCE_BATCH_FORM_STEPS } from '../../data/formSteps';
import { LabActivityKey } from '../../data/labActivities';
import useToastDetails, { TOAST_TIMER } from '../../hooks/useToast';
import {
  ACTION_PERFORMED_TITLE,
  ADD_BATCH_TITLE,
  ADD_SUBSTANCE_TITLE,
  BATCH_REGISTRATION_TITLE,
  MATERIAL_REGISTRATION_TITLE
} from '../../i18n/trsl/home';
import ProgressStepper from '../core/ProgressStepper/ProgressStepper';
import { SnackbarAlert } from '../core/SnackbarAlert/SnackbarAlert';
import BatchRegistrationWrapper from '../material-registration/BatchRegistrationWrapper/BatchRegistrationWrapper';
import MaterialRegistrationWrapper from '../material-registration/MaterialRegistrationWrapper/MaterialRegistrationWrapper';
import DuplicateSubstanceAlert, {
  DuplicationWithData
} from '../material-registration/forms/DuplicateSubstanceAlert/DuplicateSubstanceAlert';
import { getDefaultBatchFields } from '../material-registration/forms/formUtils/batchUtils';
import { getDefaultSubstanceForm } from '../material-registration/forms/formUtils/substanceUtils';
import { BatchFormFields } from '../material-registration/types/batchFields';
import { SubstanceFormFields } from '../material-registration/types/formFields';
import ModalView from '../views/ModalView/ModalView';

const DEFAULT_DUPLICATION_STATE: DuplicationWithData = {
  substance: {},
  duplication: false
};

export type SelectedAction = LabActivityKey | undefined;
interface MaterialBatchModalProps {
  selectedAction: SelectedAction;
  /* eslint-disable no-unused-vars */
  updateSelectedAction: (selectedAction: SelectedAction) => void;
}

export const MaterialBatchModal: React.FC<MaterialBatchModalProps> = ({
  selectedAction,
  updateSelectedAction
}) => {
  const [substances, setSubstances] = useState<ParsedSubstance[]>([]);
  const [batches, setBatches] = useState<BatchFormFields[]>([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [duplicateSubstance, setDuplicateSubstance] =
    useState<DuplicationWithData>(DEFAULT_DUPLICATION_STATE);

  const snackbarDetails = useToastDetails();
  const { toastDetails, updateToast, clearToast } = snackbarDetails;

  const handleSubstance: SubmitHandler<any> = (data: SubstanceFormFields) => {
    const { category } = data;
    const formData = data[category];

    const addSubstance = async () => {
      setIsSpinning(true);
      const newSubstance: ApiResponseData<CodeWithResultSubstanceRegister> =
        await registerSubstance({ category, formData });
      if (newSubstance.code === SuccessResponseCode.SUBSTANCE_REGISTERED) {
        setSubstances([
          ...substances,
          parseSubstanceFields(newSubstance?.result)
        ]);
        setBatchValue('substanceId', newSubstance?.result.id);
      }
      updateToast({
        responseType: newSubstance?.responseType as ResponseType,
        message: newSubstance?.message
      });
      setIsSpinning(false);
      if (newSubstance?.code !== SuccessResponseCode.SUBSTANCE_ALREADY_EXISTS) {
        updateSelectedAction(LabActivityKey.BATCH_REGISTRATION);
        setDuplicateSubstance(DEFAULT_DUPLICATION_STATE);
      } else {
        setDuplicateSubstance({
          duplication: true,
          substance: parseSubstanceFields(newSubstance?.result)
        });
      }
    };
    addSubstance();
  };

  const handleBatch: SubmitHandler<any> = (data: BatchFormFields) => {
    setIsSpinning(true);
    const addBatch = async () => {
      const newBatch: ApiResponseData<any> = await registerBatch(data);
      if (newBatch.code === SuccessResponseCode.SUBSTANCE_REGISTERED) {
        setBatches([...batches, parseBatchFields(newBatch?.result)]);
      }
      updateToast({
        responseType: newBatch?.responseType as ResponseType,
        message: newBatch?.message
      });
      setIsSpinning(false);
      if (newBatch.code !== SuccessResponseCode.SUBSTANCE_NOT_FOUND) {
        handleModalClose();
      }
    };
    addBatch();
  };

  const {
    control: controlSubstance,
    handleSubmit: handleSubmitSubstance,
    watch: watchSubstance,
    reset: resetSubstance
  } = useForm<SubstanceFormFields>({
    defaultValues: getDefaultSubstanceForm(),
    mode: 'onChange'
  });

  const {
    control: controlBatch,
    handleSubmit: handleSubmitBatch,
    reset: resetBatch,
    setValue: setBatchValue
  } = useForm<BatchFormFields>({
    defaultValues: getDefaultBatchFields(),
    mode: 'onChange'
  });

  const formCategory = watchSubstance('category');
  const primerCategory = watchSubstance('primer.primerCategory');

  const handleModalClose = (reason?: unknown) => {
    if (reason !== 'backdropClick') {
      updateSelectedAction(undefined);
      resetSubstance();
      resetBatch();
      setDuplicateSubstance(DEFAULT_DUPLICATION_STATE);
    }
  };

  const isOpen = useMemo(
    () =>
      selectedAction === LabActivityKey.MATERIAL_REGISTRATION ||
      selectedAction === LabActivityKey.BATCH_REGISTRATION,
    [selectedAction]
  );
  const activeStep = useMemo(
    () => getActiveStep(selectedAction),
    [selectedAction]
  );

  return (
    <>
      {!!selectedAction && (
        <ModalView
          isOpen={isOpen}
          onClose={reason => handleModalClose(reason)}
          handleSubmit={
            selectedAction === LabActivityKey.MATERIAL_REGISTRATION
              ? handleSubmitSubstance(handleSubstance)
              : handleSubmitBatch(handleBatch)
          }
          modalTitle={getModalLabel(selectedAction)}
          isSpinning={isSpinning}
          nextButtonLabel={
            activeStep === 0 ? ADD_SUBSTANCE_TITLE.en : ADD_BATCH_TITLE.en
          }
        >
          <ProgressStepper
            activeStep={activeStep}
            optionalStep={1}
            steps={SUBSTANCE_BATCH_FORM_STEPS}
          />
          {selectedAction === LabActivityKey.MATERIAL_REGISTRATION && (
            <MaterialRegistrationWrapper
              control={controlSubstance}
              category={formCategory}
              primerCategory={primerCategory}
            />
          )}
          {selectedAction === LabActivityKey.BATCH_REGISTRATION && (
            <BatchRegistrationWrapper control={controlBatch} />
          )}
          <DuplicateSubstanceAlert
            duplicateSubstance={duplicateSubstance}
            handleSkip={() => {
              setBatchValue('substanceId', duplicateSubstance.substance?.id);
              updateSelectedAction(LabActivityKey.BATCH_REGISTRATION);
              setDuplicateSubstance(DEFAULT_DUPLICATION_STATE);
            }}
          />
        </ModalView>
      )}
      <SnackbarAlert
        isOpen={!!toastDetails?.message}
        message={toastDetails?.message ?? ACTION_PERFORMED_TITLE.en}
        alertType={toastDetails?.responseType ?? 'info'}
        onClose={() => clearToast()}
        timer={TOAST_TIMER}
      />
    </>
  );
};

const getActiveStep = (selectedAction?: SelectedAction) => {
  switch (selectedAction) {
    case LabActivityKey.MATERIAL_REGISTRATION:
      return 0;
    case LabActivityKey.BATCH_REGISTRATION:
      return 1;
    default:
      return 0;
  }
};

const getModalLabel = (selectedAction: SelectedAction): string => {
  switch (selectedAction) {
    case LabActivityKey.MATERIAL_REGISTRATION:
      return MATERIAL_REGISTRATION_TITLE.en;
    case LabActivityKey.BATCH_REGISTRATION:
      return BATCH_REGISTRATION_TITLE.en;
    default:
      return '';
  }
};
