import { Alert, Snackbar } from '@mui/material';
import { useEffect } from 'react';
import { ResponseType } from '../../../apis/api';

export interface AlertSnackbarProps {
  isOpen: boolean;
  onClose: () => void;
  alertType: ResponseType | 'info';
  message: string;
  timer?: number;
}

export const SnackbarAlert = ({
  isOpen,
  onClose,
  alertType,
  message,
  timer = 5000
}: AlertSnackbarProps) => {
  useEffect(() => {
    // Resetting after each timer
    if (isOpen) {
      setTimeout(() => {
        onClose();
      }, timer);
    }
    return () => clearTimeout(timer);
  }, [isOpen, onClose, timer]);

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={timer}
    >
      <Alert
        onClose={onClose}
        severity={alertType}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
