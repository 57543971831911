import { SUPPLIER_BATCH_NUMBER_LABEL } from '../i18n/trsl/batchRegistration';
import {
  CATALOGUE_NUMBER_LABEL,
  CREATED_LABEL,
  ENTREZ_ID_LABEL,
  ID_LABEL,
  NAME_LABEL,
  REGISTERED_BY_LABEL,
  SUBSTANCE_CATEGORY_LABEL,
  SUPPLIER_LABEL,
  TARGET_LABEL
} from '../i18n/trsl/home';

export type DataSelector = {
  label: string;
  value: string;
};

/* eslint-disable no-unused-vars */
export enum SearchFilterKeys {
  ID = 'id',
  NAME = 'substanceName',
  SUBSTANCE_CATEGORY = 'substanceCategory',
  SUPPLIER = 'supplier',
  REGISTERED_BY = 'registeredBy',
  CREATED = 'created',
  ENTREZ_ID = 'entrezId',
  SUPPLIER_BATCH_NUMBER_LABEL = 'supplierBatchNumber',
  TARGET = 'targetGene',
  CATALOGUE_NUMBER = 'catalogueNumber'
}

export const searchFilters: DataSelector[] = [
  { label: ID_LABEL.en, value: SearchFilterKeys.ID },
  { label: NAME_LABEL.en, value: SearchFilterKeys.NAME },
  {
    label: SUBSTANCE_CATEGORY_LABEL.en,
    value: SearchFilterKeys.SUBSTANCE_CATEGORY
  },
  { label: SUPPLIER_LABEL.en, value: SearchFilterKeys.SUPPLIER },
  { label: REGISTERED_BY_LABEL.en, value: SearchFilterKeys.REGISTERED_BY },
  { label: CREATED_LABEL.en, value: SearchFilterKeys.CREATED },
  { label: ENTREZ_ID_LABEL.en, value: SearchFilterKeys.ENTREZ_ID },
  {
    label: SUPPLIER_BATCH_NUMBER_LABEL.en,
    value: SearchFilterKeys.SUPPLIER_BATCH_NUMBER_LABEL
  },
  { label: TARGET_LABEL.en, value: SearchFilterKeys.TARGET },
  { label: CATALOGUE_NUMBER_LABEL.en, value: SearchFilterKeys.CATALOGUE_NUMBER }
];
