import { Typography, Input, Box } from '@mui/material';
import { PairInputDetails } from './inputPairDetails';
import React from 'react';

interface InputPairProps {
  idx: number;
  input1: PairInputDetails;
  input2: PairInputDetails;
}

const InputPair = ({ input1, input2, idx }: InputPairProps) => (
  <Box m={4} display="flex">
    <Typography variant="subtitle1" sx={{ mr: 2 }}>
      {idx + 1}
    </Typography>
    <Input disabled value={input1?.value} sx={{ mr: 2 }} fullWidth />
    <Input disabled value={input2?.value} fullWidth />
  </Box>
);

export default InputPair;
