import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LOGO from '../../../assets/logo.png';
import { useAuth } from '../../../hooks/useAuth';
import trsl from '../../../i18n/index';
import { HOME_PAGE, LOGIN } from '../../../routes/consts';

export const HeaderBar = () => {
  const { LANDING_PAGE_HEADER, LOGOUT_BUTTON } = trsl.appBar;

  // TODO: Refactor this, it has been copied from the NavbarPage.tsx in order to remove the old component
  const auth = useAuth();
  const navigate = useNavigate();
  const logoutHandler = async () => {
    auth?.logout();
    localStorage.clear();
    sessionStorage.clear();
    navigate(`/${LOGIN}`);
  };

  const navigateHome = () => {
    navigate(`/${HOME_PAGE}`);
  };

  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={navigateHome}
        >
          <Avatar
            alt="NN Logo"
            variant="square"
            src={LOGO}
            sx={{ width: 80, height: '100%' }}
          />
        </IconButton>
        <Typography variant="h6">{LANDING_PAGE_HEADER.en}</Typography>
        <Button color="inherit" aria-label="Log Out" onClick={logoutHandler}>
          {LOGOUT_BUTTON.en}
        </Button>
      </Toolbar>
    </AppBar>
  );
};
