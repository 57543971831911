import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface ActivityCardProps {
  title: string;
  image: string | ReactNode;
  description: string;
}

export const DISABLED_CARD_SX = { opacity: 0.8, pointerEvents: 'none' };

export const ActivityCard = ({
  title,
  description,
  image
}: ActivityCardProps) => (
  <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    {typeof image === 'string' ? (
      <CardMedia
        component="img"
        height="100"
        width="100%"
        image={image}
        alt={title}
        sx={{ objectFit: 'contain' }}
      />
    ) : (
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px'
        }}
      >
        {image}
      </Box>
    )}
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography textAlign="center" variant="h5" component="h2">
        {title}
      </Typography>
      <Typography sx={{ mt: 2 }} color="textSecondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);
