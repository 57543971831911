import { Step, StepLabel, Stepper } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import trsl from '../../../i18n/index';

interface StepperProps {
  activeStep: number;
  optionalStep: number;
  steps: string[];
}

const isStepOptional = (optionalStep: number, step: number) =>
  step === optionalStep;

const ProgressStepper = ({ steps, activeStep, optionalStep }: StepperProps) => {
  const { OPTIONAL_LABEL } = trsl.modal;

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        if (isStepOptional(index, optionalStep)) {
          labelProps.optional = (
            <Typography variant="caption">{OPTIONAL_LABEL.en}</Typography>
          );
        }
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default ProgressStepper;
