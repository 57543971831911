import { Box, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import React from 'react';
import { Control, Controller } from 'react-hook-form';
import trsl from '../../../i18n/index';
import {
  CATALOGUE_NUMBER_HELP_LABEL,
  FLAG_HELP_LABEL,
  SUPPLIER_BATCH_NUMBER_LABEL,
  VENDOR_SUPPLIED_ID_HELP_LABEL
} from '../../../i18n/trsl/batchRegistration';
import { COMMENTS_LABEL } from '../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL
} from '../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../utils/regex';
import Dropdown from '../../core/Dropdown/Dropdown';
import { flagData } from '../forms/formData';
import { BatchFormFields } from '../types/batchFields';

interface BatchRegistrationWrapperProps {
  control: Control<BatchFormFields>;
}

const BatchRegistrationWrapper: React.FC<BatchRegistrationWrapperProps> = ({
  control
}: BatchRegistrationWrapperProps) => {
  const {
    VENDOR_SUPPLIED_ID_LABEL,
    SUBSTANCE_ID_LABEL,
    CATALOGUE_NUMBER_LABEL,
    EXPIRATION_DATE_LABEL,
    FLAG_LABEL,
    SUPPLIER_LABEL
  } = trsl.batchRegistration;
  const { REQUIRED_LABEL } = trsl.validation;

  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name={'supplierBatchNumber'}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            label={SUPPLIER_BATCH_NUMBER_LABEL.en}
            margin="normal"
          />
        )}
      />
      <Controller
        name={'substanceId'}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            label={SUBSTANCE_ID_LABEL.en}
            margin="normal"
          />
        )}
      />
      <Controller
        name={'vendorSuppliedId'}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={VENDOR_SUPPLIED_ID_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={
              fieldState?.error?.message || VENDOR_SUPPLIED_ID_HELP_LABEL.en
            }
          />
        )}
      />
      <Controller
        name={'expirationDate'}
        control={control}
        rules={{ required: REQUIRED_LABEL.en }}
        render={({ field, fieldState }) => (
          <Box mt={1} sx={{ width: '100%', my: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {...field}
                label={EXPIRATION_DATE_LABEL.en}
                slotProps={{
                  textField: {
                    helperText: fieldState?.error?.message,
                    error: !!fieldState?.error
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        )}
      />
      <Controller
        name={'supplier'}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            label={SUPPLIER_LABEL.en}
            margin="normal"
          />
        )}
      />
      <Controller
        name={'catalogueNumber'}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={CATALOGUE_NUMBER_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={
              fieldState?.error?.message || CATALOGUE_NUMBER_HELP_LABEL.en
            }
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={FLAG_LABEL.en}
        controlName="flag"
        data={flagData}
        helperText={FLAG_HELP_LABEL.en}
      />
      <Controller
        name={'comments'}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={COMMENTS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default BatchRegistrationWrapper;
