import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useAuthHandler } from '../../../hooks/useAuthHandler';
import trsl from '../../../i18n/index';

const { FOOTER_CONTACT, FOOTER_CREDITS, WELCOME_MESSAGE, LOGIN_BUTTON } =
  trsl.landingPage;

const Footer = () => (
  <Box
    position="fixed"
    bottom={0}
    width="100%"
    bgcolor="#f8f9fa"
    color="black"
    p={2}
    display="flex"
    justifyContent="space-between"
  >
    <Typography>{FOOTER_CONTACT.en}</Typography>
    <Typography>{FOOTER_CREDITS.en}</Typography>
  </Box>
);

const MainContent = () => {
  return (
    <Container sx={{ marginTop: '30px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        {WELCOME_MESSAGE.en}
      </Typography>
      <LoginFinal />
    </Container>
  );
};

const LandingPage = () => {
  return (
    <>
      <MainContent />
      <Footer />
    </>
  );
};

const LoginFinal: React.FC<{}> = () => {
  const { loginHandler } = useAuthHandler();

  return (
    <Box display="flex" justifyContent="center">
      <Button variant="contained" color="primary" onClick={loginHandler}>
        {LOGIN_BUTTON.en}
      </Button>
    </Box>
  );
};

export default LandingPage;
