import { Box, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { COMMENTS_LABEL } from '../../../../i18n/trsl/batchRegistration';
import { NAME_LABEL } from '../../../../i18n/trsl/home';
import {
  ALIAS_LABEL,
  FORMULATION_TYPE_LABEL,
  HAZARDS_LABEL,
  SEQUENCE_ID_AND_SEQUENCE_LABEL,
  TARGET_GENE_LABEL
} from '../../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import InputPairListControl from '../../../core/InputPairList/InputPairListControl';
import { RadioGroupSelector } from '../../../core/RadioGroupSelector/RadioGroupSelector';
import {
  ArrayFormulationHooks,
  FormulationSirnaFormFields
} from '../../types/formulationFields';
import {
  FormulationTypeValue,
  formulationTypeData,
  hazardsData
} from '../formData';

interface SirnaSetFormProps {
  control: Control<FormulationSirnaFormFields>;
  arrayHooks: ArrayFormulationHooks;
}

const SirnaSetForm: React.FC<SirnaSetFormProps> = ({
  control,
  arrayHooks
}: SirnaSetFormProps) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name="formulationType"
        control={control}
        rules={{ required: REQUIRED_LABEL.en }}
        render={({ field, fieldState }) => (
          <RadioGroupSelector
            field={field}
            row
            label={FORMULATION_TYPE_LABEL.en}
            options={formulationTypeData}
            error={!!fieldState?.error}
            disabledOptions={[FormulationTypeValue.SET]}
          />
        )}
      />
      <Controller
        name="substanceName"
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={NAME_LABEL.en}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name="targetGene"
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={TARGET_GENE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={HAZARDS_LABEL.en}
        controlName="hazards"
        data={hazardsData}
      />
      <Controller
        name="aliasName"
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ALIAS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <InputPairListControl
        inputLabel={SEQUENCE_ID_AND_SEQUENCE_LABEL.en}
        arrayHooks={arrayHooks}
        required
        parseInput={(value1: string, value2: string) => ({
          sequenceId: value1,
          sequence: value2
        })}
      />
      <Controller
        name="comments"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label={COMMENTS_LABEL.en}
            {...field}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default SirnaSetForm;
