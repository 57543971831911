import { createContext, useContext } from 'react';

interface AuthContextProps {
  user: string | null;
  /* eslint-disable no-unused-vars */
  login: (user: string, token: string) => void;
  /* eslint-enable no-unused-vars */
  logout: () => void;
  token: string | null;
}

const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuth = (): AuthContextProps | null => {
  return useContext(AuthContext);
};
