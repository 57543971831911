import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { REQUIRED_LABEL } from '../../../i18n/trsl/validation';
import { DataSelector } from '../../material-registration/forms/formData';
import { colors } from '../../stylings/colors';

interface RadioGroupSelectorProps {
  label: string;
  options: DataSelector[];
  defaultValue?: string;
  row?: boolean;
  error?: boolean;
  field?: any;
  disabledOptions?: string[];
}

export const RadioGroupSelector = ({
  label,
  options,
  defaultValue,
  row,
  error,
  field,
  disabledOptions
}: RadioGroupSelectorProps) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel> {/* to be replaced with novo label  */}
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        row={row}
        {...field}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={label}
            disabled={disabledOptions?.includes(value)}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText sx={{ color: colors.errorRed }}>
          {REQUIRED_LABEL.en}
        </FormHelperText>
      )}
    </FormControl>
  );
};
