import { Add as AddIcon } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import {
  ADD_FILTER_LABEL,
  ENTER_FILTER_VALUE_PLACEHOLDER,
  SEARCH_CHOOSE_FILTER_PLACEHOLDER
} from '../../../../i18n/trsl/home';
import { DataSelector } from '../../../material-registration/forms/formData';
import { colors } from '../../../stylings/colors';

interface SearchFiltersProps {
  searchFilters: DataSelector[];
  /* eslint-disable no-unused-vars */
  handleAddFilter: (value: FilterPair) => void;
}

export type FilterPair = {
  filterType: DataSelector;
  filterValue: string;
};

export const SearchFilters = ({
  searchFilters,
  handleAddFilter
}: SearchFiltersProps) => {
  const [filterTypeValue, setFilterTypeValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');

  const isAddDisabled = useMemo(
    () => !filterTypeValue || !filterValue,
    [filterTypeValue, filterValue]
  );

  const handleFilterSubmit = () => {
    if (filterTypeValue && filterValue) {
      handleAddFilter({
        filterType: searchFilters.find(
          filter => filter.value === filterTypeValue
        ) ?? { label: filterTypeValue, value: filterTypeValue },
        filterValue: filterValue
      });
      setFilterTypeValue('');
      setFilterValue('');
    }
  };

  const handleFieldChange = (event: SelectChangeEvent<string>) => {
    setFilterTypeValue(event.target.value);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ m: 2 }}
    >
      <FormControl sx={{ minWidth: 140 }}>
        <InputLabel data-testid="search-filter-label">
          {SEARCH_CHOOSE_FILTER_PLACEHOLDER.en}
        </InputLabel>
        <Select
          data-testid="select-element"
          labelId="search-filter-label"
          value={filterTypeValue}
          onChange={handleFieldChange}
          label={SEARCH_CHOOSE_FILTER_PLACEHOLDER.en}
          autoWidth
        >
          {searchFilters.map(searchFilter => (
            <MenuItem
              sx={{ minWidth: 140 }}
              key={searchFilter.value}
              value={searchFilter.value}
            >
              {searchFilter.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        placeholder={ENTER_FILTER_VALUE_PLACEHOLDER.en}
        data-testid="input-element"
        value={filterValue}
        onChange={handleInputChange}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleFilterSubmit();
          }
        }}
        sx={{ margin: '0 20px', minWidth: 150 }}
      />
      <FilterButton
        isAddDisabled={isAddDisabled}
        handleFilterSubmit={handleFilterSubmit}
      />
    </Box>
  );
};

type FilterButtonProps = {
  isAddDisabled: boolean;
  handleFilterSubmit: () => void;
};

const FilterButton = ({
  isAddDisabled,
  handleFilterSubmit
}: FilterButtonProps) => (
  <IconButton
    data-testid="add-button"
    onClick={handleFilterSubmit}
    disabled={isAddDisabled}
    sx={{
      border: `2px solid ${isAddDisabled ? colors.lightGray : colors.primaryBlue}`,
      color: `${isAddDisabled ? colors.mediumGray : colors.primaryBlue}`,
      backgroundColor: `${colors.nearWhite}`,
      borderRadius: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: `${isAddDisabled ? colors.veryLightGray : colors.primaryBlue}`,
        color: colors.white,
        '& svg': {
          color: colors.white
        }
      },
      '& svg': {
        // This targets the svg inside the IconButton, in this case the AddIcon
        color: `${isAddDisabled ? colors.mediumGray : colors.primaryBlue}`
      }
    }}
  >
    <AddIcon />
    <span style={{ fontSize: '0.6em' }}>{ADD_FILTER_LABEL.en}</span>
  </IconButton>
);
