import { Box, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SubstanceCategoryKey } from '../../../../data/substances';
import {
  ALIAS_LABEL,
  BACTERIAL_RESISTANCE_LABEL,
  COMMENTS_LABEL,
  HAZARDS_LABEL,
  NAME_LABEL,
  PROMOTER_LABEL,
  SELECTION_RESISTANCE_LABEL,
  SUBSTANCE_STATE_LABEL,
  TARGET_GENE_LABEL,
  VECTOR_BUILDER_URL_LABEL
} from '../../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import { SubstanceFormFields } from '../../types/formFields';
import { hazardsData, substanceStateData } from '../formData';

interface PlasmidFormProps {
  control: Control<SubstanceFormFields>;
}

const PlasmidForm: React.FC<PlasmidFormProps> = ({
  control
}: PlasmidFormProps) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.reagentName`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            label={NAME_LABEL.en}
            margin="normal"
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.targetGene`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={TARGET_GENE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={HAZARDS_LABEL.en}
        controlName={`${SubstanceCategoryKey.PLASMID}.hazards`}
        data={hazardsData}
      />
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.aliasName`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ALIAS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.vectorBuilderUrl`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={VECTOR_BUILDER_URL_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.bacterialResistance`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={BACTERIAL_RESISTANCE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.selectionResistance`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={SELECTION_RESISTANCE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.promoter`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={PROMOTER_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        rules={{ required: REQUIRED_LABEL.en }}
        inputLabel={SUBSTANCE_STATE_LABEL.en}
        controlName={`${SubstanceCategoryKey.PLASMID}.substanceState`}
        data={substanceStateData}
      />
      <Controller
        name={`${SubstanceCategoryKey.PLASMID}.comments`}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label={COMMENTS_LABEL.en}
            {...field}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default PlasmidForm;
