import { Box, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SubstanceCategoryKey } from '../../../../data/substances';
import { COMMENTS_LABEL } from '../../../../i18n/trsl/batchRegistration';
import { ENTREZ_ID_LABEL, NAME_LABEL } from '../../../../i18n/trsl/home';
import {
  ALIAS_LABEL,
  CGRNA_NAME_LABEL,
  ENDONUCLEASE_LABEL,
  HAZARDS_LABEL,
  KO_A_LABEL,
  NCBI_SUBSTANCE_LABEL,
  PROMOTER_LABEL,
  RNA_TYPE_LABEL,
  SEQUENCE_ID_LABEL,
  SEQUENCE_LABEL,
  TARGET_GENE_LABEL
} from '../../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import { SubstanceFormFields } from '../../types/formFields';
import { hazardsData, koaData, rnaTypeData } from '../formData';

interface RNAFormProps {
  control: Control<SubstanceFormFields>;
}

const RNAForm: React.FC<RNAFormProps> = ({ control }: RNAFormProps) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name={`${SubstanceCategoryKey.RNA}.reagentName`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={NAME_LABEL.en}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            margin="normal"
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={RNA_TYPE_LABEL.en}
        rules={{ required: REQUIRED_LABEL.en }}
        controlName={`${SubstanceCategoryKey.RNA}.rnaType`}
        data={rnaTypeData}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.targetGene`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={TARGET_GENE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />

      <Dropdown
        control={control}
        inputLabel={HAZARDS_LABEL.en}
        controlName={`${SubstanceCategoryKey.RNA}.hazards`}
        data={hazardsData}
      />

      <Controller
        name={`${SubstanceCategoryKey.RNA}.aliasName`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ALIAS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.sequence`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={SEQUENCE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.ncbiSubstanceId`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={NCBI_SUBSTANCE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.entrezId`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ENTREZ_ID_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.sequenceId`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={SEQUENCE_ID_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.cgRNAName`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={CGRNA_NAME_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.endonuclease`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ENDONUCLEASE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.RNA}.promoter`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={PROMOTER_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={KO_A_LABEL.en}
        controlName={`${SubstanceCategoryKey.RNA}.koa`}
        data={koaData}
      />

      <Controller
        name={`${SubstanceCategoryKey.RNA}.comments`}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label={COMMENTS_LABEL.en}
            {...field}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default RNAForm;
