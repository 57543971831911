import { useState } from 'react';
import { ResponseType } from '../apis/api';

export const TOAST_TIMER = 10000;

export type ToastDetails = {
  message: string;
  responseType: ResponseType;
};

const useToastDetails = () => {
  const [toastDetails, setToastDetails] = useState<ToastDetails | null>(null);

  const updateToast = ({ message, responseType }: ToastDetails) => {
    setToastDetails({ message, responseType });
  };

  const clearToast = () => {
    setToastDetails(null);
  };

  return {
    toastDetails,
    updateToast,
    clearToast
  };
};

export default useToastDetails;
