import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FetchUserResponse, fetchUser } from '../apis/fetchUser/fetchUser';
import { useAuth } from '../hooks/useAuth';
import { HOME_PAGE } from '../routes/consts';

export const useAuthHandler = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string | null>(null);

  const auth = useAuth();
  const navigateHome = useCallback(() => {
    if (username) {
      navigate(`../${HOME_PAGE}`);
    }
  }, [username, navigate]);

  useEffect(() => {
    const loginPressed = sessionStorage.getItem('loginPressed') === 'true';

    if (loginPressed) getUserAndToken();

    if (username !== null && username.length > 0) {
      navigateHome();
    }
  }, [navigateHome]);

  const getUserAndToken = async () => {
    try {
      const { user, token } = (await fetchUser()) as FetchUserResponse;

      [window.sessionStorage, window.localStorage].forEach(storage => {
        storage.setItem('username', user);
        storage.setItem('jwtToken', token);
      });

      setUsername(user);
      auth?.login(user, token);
    } catch (error) {
      throw new Error(`Failed to extract user and token: ${error}`);
    }
  };

  const loginHandler = async () => {
    sessionStorage.setItem('loginPressed', 'true');

    if (username !== null && username.length > 0) {
      navigate(`../${HOME_PAGE}`);
    } else {
      Auth.federatedSignIn({ customProvider: 'AzureAD' });
    }
  };

  return { loginHandler };
};
