import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ReactNode, useEffect, useState } from 'react';
import { INPUT_DESCRIPTION } from '../../../i18n/trsl/formulation';
import {
  APHANUM_SPACE_DASH_LABEL,
  MULTILINE_ERROR
} from '../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_REGEX,
  WHITESPACE,
  WHITESPACE_REGEX
} from '../../../utils/regex';
import InputPair from '../InputPair/InputPair';
import { Pair, ParseError } from './inputPairListDetails';

interface InputPairListControlProps {
  inputLabel: string;
  defaultValue?: string;
  arrayHooks: {
    replace: any;
  };
  // eslint-disable-next-line no-unused-vars
  parseInput: (value1: string, value2: string) => Pair;
  required?: boolean;
}

const InputPairListControl = ({
  inputLabel,
  defaultValue,
  arrayHooks,
  required,
  parseInput
}: InputPairListControlProps) => {
  const { replace } = arrayHooks;

  const [input, setInput] = useState('');
  const [parsingError, setParsingError] = useState<ParseError>({
    error: false,
    message: undefined
  });

  const [allEntries, setAllEntries] = useState<ReactNode[] | null>(null);

  const handleInputChange = (event: any) => {
    setInput(event?.target?.value);
  };

  const parseMultipleLines = (input: string) => {
    const pairsArray = input.split('\n').reduce((accumulator: Pair[], line) => {
      const [input1, input2] = parseLineInputAndValidate(line);
      if (input1 && input2) {
        return [...accumulator, parseInput(input1, input2)];
      }
      return accumulator;
    }, []);
    replace(pairsArray);
    return inputPairList(pairsArray);
  };

  const parseLineInputAndValidate = (input: string): string[] => {
    if (!APHANUM_SPACE_DASH_REGEX.test(input)) {
      setParsingError({
        error: true,
        message: APHANUM_SPACE_DASH_LABEL.en
      });
      return [];
    }

    const lineEntries = input
      .trim()
      .replace(WHITESPACE_REGEX, WHITESPACE)
      .split(WHITESPACE);

    if (lineEntries.length !== 2) {
      setParsingError({
        error: true,
        message: MULTILINE_ERROR.en
      });
      return [];
    }
    setParsingError({ error: false, message: undefined });
    return lineEntries;
  };

  const inputPairList = (input: Pair[]) =>
    input.map((entry, idx: number) => {
      const pairValues: string[] = Object.values(entry);
      return (
        <InputPair
          key={idx}
          input1={{ value: pairValues[0] }}
          input2={{ value: pairValues[1] }}
          idx={idx}
        />
      );
    });

  useEffect(() => {
    input.length === 0
      ? setAllEntries(!defaultValue ? null : parseMultipleLines(defaultValue))
      : setAllEntries(parseMultipleLines(input));
  }, [input, defaultValue]);

  return (
    <Box my={2}>
      <TextField
        required={required}
        label={inputLabel}
        multiline
        rows={5}
        onChange={handleInputChange}
        fullWidth
        error={parsingError.error}
        helperText={parsingError.message}
        defaultValue={defaultValue}
      />
      <Typography mx={2} my={1} variant="subtitle2">
        {INPUT_DESCRIPTION.en}
      </Typography>
      {allEntries}
    </Box>
  );
};

export default InputPairListControl;
