import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from '../auth/RequireAuth';
import { BatchDetails } from '../components/views/BatchDetails/BatchDetails';
import HomePage from '../components/views/HomePage/HomePage';
import LandingPage from '../components/views/LandingPage/LandingPage';
import SearchData from '../components/views/SearchPage/SearchData';
import { SubstanceDetails } from '../components/views/SubstanceDetails/SubstanceDetails';
import {
  BATCHES,
  BATCH_ID,
  HOME_PAGE,
  LOGIN,
  SUBSTANCES,
  SUBSTANCE_ID
} from './consts';

export const AppRoutes = () => (
  <Routes>
    <Route path={`/${LOGIN}`} element={<LandingPage />} />

    <Route
      path={`/${HOME_PAGE}`}
      element={
        <RequireAuth>
          <HomePage />
        </RequireAuth>
      }
    />
    <Route
      path={`/${SUBSTANCES}`}
      element={
        <RequireAuth>
          <SearchData />
        </RequireAuth>
      }
    />
    <Route
      path={`/${SUBSTANCES}/:${SUBSTANCE_ID}`}
      element={
        <RequireAuth>
          <SubstanceDetails />
        </RequireAuth>
      }
    />
    <Route
      path={`/${BATCHES}/:${BATCH_ID}`}
      element={
        <RequireAuth>
          <BatchDetails />
        </RequireAuth>
      }
    />

    {/* Root will match any path. It's a common practice to place it at the end so that it serves as a fallback. */}
    <Route path="/" element={<LandingPage />} />
  </Routes>
);
