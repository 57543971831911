import { i18n } from '../i18nType';

export const BATCH_CREATION_LABEL: i18n = { en: 'Batch Creation' };
export const SUPPLIER_BATCH_NUMBER_LABEL: i18n = {
  en: 'Supplier Batch Number'
};
export const SUBSTANCE_ID_LABEL: i18n = { en: 'Substance ID' };
export const SUPPLIER_ID_LABEL: i18n = { en: 'Supplier ID' };
export const CREATED_LABEL: i18n = { en: 'Created' };
export const UPDATED_LABEL: i18n = { en: 'Updated' };
export const VENDOR_ID_LABEL: i18n = { en: 'Vendor ID' };
export const CLASS_LABEL: i18n = { en: 'Class' };
export const EXPIRY_LABEL: i18n = { en: 'Expiry' };
export const VENDOR_SUPPLIED_ID_LABEL: i18n = { en: 'Vendor supplied ID' };
export const EXPIRATION_DATE_LABEL: i18n = { en: 'Expiration Date' };
export const SUPPLIER_LABEL: i18n = { en: 'Supplier' };
export const CATALOGUE_NUMBER_LABEL: i18n = { en: 'Catalogue Number' };
export const FLAG_LABEL: i18n = { en: 'Flag' };
export const YES_LABEL: i18n = { en: 'Yes' };
export const NO_LABEL: i18n = { en: 'No' };
export const COMMENTS_LABEL: i18n = { en: 'Comments' };
export const FLAG_HELP_LABEL: i18n = {
  en: 'Mark YES if the batch is of high value/priority'
};
export const VENDOR_SUPPLIED_ID_HELP_LABEL: i18n = {
  en: 'Substance ID provided by the supplier'
};
export const CATALOGUE_NUMBER_HELP_LABEL: i18n = {
  en: 'Catalogue number of the substance provided by the supplier'
};

export const ID = { en: 'ID' };
export const SUPPLIER = { en: 'Supplier' };
export const CATALOG_NUMBER = { en: 'Catalog Number' };
export const BATCHES_INFORMATION = { en: 'Batches Information' };
export const INVALID_DATE = { en: 'Invalid date' };
