export enum ResponseType {
  /* eslint-disable no-unused-vars */
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning'
  /* eslint-disable no-unused-vars */
}

export enum SuccessResponseCode {
  /* eslint-disable no-unused-vars */
  BATCH_FOUND = 'BatchFound',
  BATCH_REGISTERED = 'BatchRegistered',
  BATCH_ALREADY_EXISTS = 'BatchAlreadyExists',
  DATABASE_ERROR = 'DatabaseError', // To further refine codes
  SUBSTANCE_ALREADY_EXISTS = 'SubstanceAlreadyExists',
  SEARCH_RESULTS_FOUND = 'SearchResultsFound',
  SUBSTANCE_FOUND = 'SubstanceFound',
  SUBSTANCE_NOT_FOUND = 'SubstanceNotFound',
  SUBSTANCE_REGISTERED = 'SubstanceRegistered',
  NO_RESULTS_FOUND = 'NoResultsFound'
  /* eslint-disable no-unused-vars */
}

export enum ErrorResponseCode {
  INVALID_INPUT = 'InvalidInput',
  BATCH_NOT_FOUND = 'BatchNotFound'
}

// Where T contains { code: SuccessResponseCode; result: any}
export type SuccessResponse<T> = {
  responseType: ResponseType.SUCCESS;
} & T;

export type ErrorResponse = {
  responseType: ResponseType.ERROR;
  code: ErrorResponseCode | SuccessResponseCode.NO_RESULTS_FOUND;
  result?: Array<unknown>; // It shouldn't be the case but the backend returns an empty array for some cases
};

export type ApiResponseData<T> = (SuccessResponse<T> | ErrorResponse) & {
  message: string;
};

export type ApiResponse<T> = {
  config: any;
  data: ApiResponseData<T>;
  headers: any;
  request: any;
  status: number;
  statustText: string;
};
