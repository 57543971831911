export const colors = {
  gray: 'rgba(0, 0, 0, 0.3)',
  darkBlue: 'rgb(0, 25, 101)',
  lightGray: '#CDCCCC',
  mediumGray: '#A3A3A3',
  nearWhite: '#FAF8F8',
  softGray: '#E0E0E0',
  veryLightGray: '#EFEFEF',
  primaryBlue: '#0072C6',
  white: '#ffffff',
  errorRed: '#d32f2f',
  veryLightBlue: '#f2f9ff',
  primaryBlueActive: '#003B8A', // NN component blue active
  black: '#000000',
  disabledGray: '#E0DCD7',
  disabledGrayText: '#A39D97'
};
