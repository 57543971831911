import { Autocomplete, Chip, TextField } from '@mui/material';
import { FC } from 'react';
import { FilterPair } from './SearchFilters';
import { colors } from '../../../stylings/colors';
import { PLEASE_SELECT_FILTERS_PLACEHOLDER } from '../../../../i18n/trsl/home';

interface AdvancedSearchWithFiltersProps {
  selectedOptions: FilterPair[];
  /* eslint-disable no-unused-vars */
  handleSelectedOptions: (newOptions: FilterPair[]) => void;
}

const AdvancedSearchWithFilters: FC<AdvancedSearchWithFiltersProps> = ({
  selectedOptions,
  handleSelectedOptions
}) => {
  return (
    <Autocomplete
      sx={{ flexBasis: { xs: '100%', md: '70%' } }}
      multiple
      options={[]}
      readOnly={true}
      value={selectedOptions}
      renderTags={(value, getTagProps) =>
        value.map(({ filterType, filterValue }, index) => (
          <Chip
            label={`${filterType.label}: ${filterValue}`}
            {...getTagProps({ index })}
            key={filterType.label}
            onDelete={() => {
              const newOptions = [...selectedOptions];
              newOptions.splice(index, 1);
              handleSelectedOptions(newOptions);
            }}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          placeholder={
            selectedOptions.length === 0
              ? PLEASE_SELECT_FILTERS_PLACEHOLDER.en
              : ''
          }
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: colors.black
              },
            minWidth: '300px',
            borderRadius: '4px',
            flexBasis: { xs: '100%', md: '70%' }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: null
          }}
        />
      )}
    />
  );
};

export default AdvancedSearchWithFilters;
