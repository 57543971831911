import axios from 'axios';
import { BATCH_PATH, createApiURL } from '../../configs/envConfig';
import { ApiResponse } from '../api';
import {
  BatchDataPayload,
  BatchFields,
  CodeWithResultBatchResult
} from './batchTypes';

export const registerBatch = async (formData: BatchFields) => {
  const BATCH_API = createApiURL({
    apiPath: BATCH_PATH
  });

  const data: BatchDataPayload = {
    ...formData,
    registeredBy: localStorage.getItem('username') || ''
  };

  const payload = JSON.stringify({ batch: data });
  const token = localStorage.getItem('jwtToken');

  try {
    if (!token) {
      throw Error('User is not logged in');
    }
    const response: ApiResponse<CodeWithResultBatchResult> = await axios.post(
      BATCH_API,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    throw Error('Error' + error);
  }
};
