import { i18n } from '../i18nType';

// FIELDS LABELS
export const NAME_LABEL: i18n = { en: 'Name' };
export const ACTIVITY_LABEL: i18n = { en: 'Activity' };
export const TARGET_GENE_LABEL: i18n = { en: 'Target/ Gene' };
export const HAZARDS_LABEL: i18n = { en: 'Hazards' };
export const COMMENTS_LABEL: i18n = { en: 'Comments' };
export const ALIAS_LABEL: i18n = { en: 'Alias' };
export const VECTOR_BUILDER_URL_LABEL: i18n = { en: 'Vector Builder URL' };
export const BACTERIAL_RESISTANCE_LABEL: i18n = { en: 'Bacterial Resistance' };
export const SELECTION_RESISTANCE_LABEL: i18n = { en: 'Selection Resistance' };
export const PROMOTER_LABEL: i18n = { en: 'Promoter' };
export const SUBSTANCE_STATE_LABEL: i18n = { en: 'State' };
export const COLOR_LABEL: i18n = { en: 'Colour' };
export const MOLWT_LABEL: i18n = { en: 'MolWt' };
export const SEQUENCE_LABEL: i18n = { en: 'Sequence' };
export const NCBI_SUBSTANCE_LABEL: i18n = { en: 'NCBI Substance' };
export const ENTREZ_ID_LABEL: i18n = { en: 'Entrez ID' };
export const SEQUENCE_ID_LABEL: i18n = { en: 'Sequence ID' };
export const SEQUENCE_ID_AND_SEQUENCE_LABEL: i18n = {
  en: 'Sequence ID and Sequence'
};
export const CGRNA_NAME_LABEL: i18n = { en: 'CgRNA name' };
export const ENDONUCLEASE_LABEL: i18n = { en: 'Endonuclease' };
export const KO_A_LABEL: i18n = { en: 'KO/A' };
export const SPECIES_LABEL: i18n = { en: 'Species' };
export const DIRECTION_LABEL: i18n = { en: 'Direction' };
export const PRIMER_CATEGORY_LABEL: i18n = { en: 'Primer Category' };
export const FLUORESCENT_DYE_LABEL = { en: 'Fluorescent Dye' };
export const QUENCHER_LABEL = { en: 'Quencher' };
export const SPECIES_RAISED_LABEL = { en: 'Species Raised' };
export const FORMULATION_LABEL = { en: 'Formulation' };
export const COMPONENTS_LABEL = { en: 'Components' };
export const KIT_LABEL: i18n = { en: 'Kit' };
export const FORMULA_LABEL: i18n = { en: 'Formula' };
export const REAGENT_NAME_LABEL: i18n = { en: 'Reagent Name' };
export const RNA_TYPE_LABEL: i18n = { en: 'RNA Type' };

// DROPDOWN VALUES LABELS
export const INHIBITION_LABEL: i18n = { en: 'Inhibition' };
export const ACTIVATION_LABEL: i18n = { en: 'Activation' };
export const NON_HAZARD_LABEL: i18n = { en: 'Non-hazard' };
export const FLAMMABLE_LABEL: i18n = { en: 'Flammable' };
export const TOXIC_LABEL: i18n = { en: 'Toxic' };
export const CORROSIVE_LABEL: i18n = { en: 'Corrosive' };
export const SOLID_LABEL: i18n = { en: 'Solid' };
export const COMBUSTIBLE_LABEL: i18n = { en: 'Combustible' };
export const ENVIRONMENTAL_LABEL: i18n = { en: 'Environmental' };
export const LIQUID_LABEL: i18n = { en: 'Liquid' };
export const GAS_LABEL: i18n = { en: 'Gas' };
export const POWDER_LABEL: i18n = { en: 'Powder' };
export const RECONSTITUTED_LABEL: i18n = { en: 'Reconstituted' };
export const OTHER_LABEL: i18n = { en: 'Other' };
export const KNOCKOUT_LABEL: i18n = { en: 'Knockout' };
export const HUMAN_LABEL: i18n = { en: 'Human' };
export const MOUSE_LABEL: i18n = { en: 'Mouse' };
export const RABBIT_LABEL: i18n = { en: 'Rabbit' };
export const GOAT_LABEL: i18n = { en: 'Goat' };
export const RAT_LABEL: i18n = { en: 'Rat' };
export const DONKEY_LABEL: i18n = { en: 'Donkey' };
export const PORCINE_LABEL: i18n = { en: 'Porcine' };
export const CHICKEN_LABEL: i18n = { en: 'Chicken' };
export const STREPTOCOCCUS_PYOGENES_LABEL: i18n = {
  en: 'Streptococcus pyogenes'
};
export const PRIMER_LABEL: i18n = { en: 'Primer' };
export const TAQMAN_LABEL: i18n = { en: 'Taqman' };
export const BACTERIAL_CULTURE_LABEL: i18n = { en: 'Bacterial Culture' };
export const GLYCEROL_LABEL: i18n = { en: 'Glycerol' };
export const FORWARD_LABEL: i18n = { en: 'Forward' };
export const REVERSE_LABEL: i18n = { en: 'Reverse' };
export const NA_LABEL: i18n = { en: 'N/A' };
export const SET_LABEL: i18n = { en: 'Set' };
export const FORMULATION_TYPE_LABEL: i18n = { en: 'Formulation Type' };
