import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { SubstanceCategoryKey, substances } from '../../../data/substances';
import trsl from '../../../i18n/index';
import Dropdown from '../../core/Dropdown/Dropdown';
import AntibodiesForm from '../forms/AntibodiesForm/AntibodiesForm';
import ChemicalStockForm from '../forms/ChemicalStockForm/ChemicalStockForm';
import ColouredProbeForm from '../forms/ColouredProbeForm/ColouredProbeForm';
import KitForm from '../forms/KitForm/KitForm';
import PlasmidForm from '../forms/PlasmidForm/PlasmidForm';
import PrimerForm from '../forms/PrimerForm/PrimerForm';
import RNAForm from '../forms/RNAForm/RNAForm';
import { PrimerCategoryValues } from '../forms/formData';
import { SubstanceFormFields } from '../types/formFields';

interface MaterialRegistrationWrapperProps {
  control: Control<SubstanceFormFields>;
  category: SubstanceCategoryKey;
  primerCategory: PrimerCategoryValues;
}

const MaterialRegistrationWrapper: React.FC<
  MaterialRegistrationWrapperProps
> = ({
  control,
  category,
  primerCategory
}: MaterialRegistrationWrapperProps) => {
  const { MATERIAL_REGISTRATION_CATEGORY } = trsl.materialRegistration;

  const substanceCategories = useMemo(
    () =>
      Object.entries(substances)
        .sort((a, b) => a[1].localeCompare(b[1]))
        .map(([key, displayName]) => ({ label: displayName, value: key })),
    []
  );

  return (
    <Box
      sx={{
        marginLeft: '5%',
        marginRight: '5%',
        overflow: 'auto',
        mt: '20px'
      }}
    >
      <Dropdown
        control={control}
        variant="filled"
        inputLabel={MATERIAL_REGISTRATION_CATEGORY.en}
        controlName="category"
        data={substanceCategories}
      />
      {getMaterialCategory(
        category as SubstanceCategoryKey,
        control,
        primerCategory
      )}
    </Box>
  );
};

export default MaterialRegistrationWrapper;

export const getMaterialCategory = (
  input: SubstanceCategoryKey,
  control: Control<SubstanceFormFields>,
  primerCategory: PrimerCategoryValues
): React.ReactNode => {
  switch (input) {
    case SubstanceCategoryKey.KIT:
      return <KitForm control={control} />;
    case SubstanceCategoryKey.PRIMER:
      return <PrimerForm control={control} primerCategory={primerCategory} />;
    case SubstanceCategoryKey.PLASMID:
      return <PlasmidForm control={control} />;
    case SubstanceCategoryKey.ANTIBODY:
      return <AntibodiesForm control={control} />;
    case SubstanceCategoryKey.COLOURED_PROBE:
      return <ColouredProbeForm control={control} />;
    case SubstanceCategoryKey.CHEMICAL_STOCK:
      return <ChemicalStockForm control={control} />;
    case SubstanceCategoryKey.RNA:
      return <RNAForm control={control} />;
  }
};
