import { Box, FormControl, InputLabel, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  CATALOGUE_NUMBER_LABEL,
  COMMENTS_LABEL,
  EXPIRATION_DATE_LABEL,
  FLAG_HELP_LABEL,
  FLAG_LABEL,
  SUBSTANCE_ID_LABEL,
  SUPPLIER_BATCH_NUMBER_LABEL,
  SUPPLIER_LABEL,
  VENDOR_SUPPLIED_ID_HELP_LABEL,
  VENDOR_SUPPLIED_ID_LABEL
} from '../../../../i18n/trsl/batchRegistration';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import SelectWithChips from '../../../core/SelectWithChips/SelectWithChips';
import { FormulationBatchFormFields } from '../../types/formulationFields';
import { flagData } from '../formData';

interface BatchFormulationFormProps {
  control: Control<FormulationBatchFormFields>;
  formulationIds: string[];
}

const BatchFormulationForm: React.FC<BatchFormulationFormProps> = ({
  control,
  formulationIds
}) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name={'supplierBatchNumber'}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            label={SUPPLIER_BATCH_NUMBER_LABEL.en}
            margin="normal"
          />
        )}
      />
      <FormControl
        sx={{
          my: 2,
          pt: 1,
          minWidth: 150
        }}
        size="medium"
      >
        <InputLabel>{SUBSTANCE_ID_LABEL.en}</InputLabel>
        <SelectWithChips
          options={formulationIds}
          value={formulationIds}
          onChange={() => {}}
          nonEditable
        />
      </FormControl>
      <Controller
        name={'vendorSuppliedId'}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={VENDOR_SUPPLIED_ID_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={
              fieldState?.error?.message || VENDOR_SUPPLIED_ID_HELP_LABEL.en
            }
          />
        )}
      />
      <Controller
        name={'expirationDate'}
        control={control}
        rules={{ required: REQUIRED_LABEL.en }}
        render={({ field, fieldState }) => (
          <Box mt={1} sx={{ width: '100%', my: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {...field}
                label={EXPIRATION_DATE_LABEL.en}
                slotProps={{
                  textField: {
                    helperText: fieldState?.error?.message,
                    error: !!fieldState?.error
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        )}
      />
      <Controller
        name={'supplier'}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            label={SUPPLIER_LABEL.en}
            margin="normal"
          />
        )}
      />
      <Controller
        name="catalogueNumber"
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={CATALOGUE_NUMBER_LABEL.en}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            margin="normal"
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={FLAG_LABEL.en}
        controlName="flag"
        data={flagData}
        helperText={FLAG_HELP_LABEL.en}
      />
      <Controller
        name={'comments'}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={COMMENTS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default BatchFormulationForm;
