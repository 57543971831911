import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import React, { ReactNode } from 'react';
import { SubmitHandler } from 'react-hook-form';
import trsl from '../../../i18n/index';

interface ModalViewProps {
  isOpen: boolean;
  isSpinning?: boolean;
  /* eslint-disable no-unused-vars */
  onClose: (reason?: unknown) => void;
  modalTitle: string;
  children: ReactNode;
  nextButtonLabel: string;
  handleSubmit: SubmitHandler<any>;
}

const ModalView: React.FC<ModalViewProps> = ({
  isOpen,
  onClose,
  modalTitle,
  children,
  nextButtonLabel,
  isSpinning,
  handleSubmit
}) => {
  const { CLOSE_LABEL } = trsl.modal;

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => onClose(reason)}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          minHeight: '80vh',
          padding: 20
        }
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {modalTitle}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClose}>
            {CLOSE_LABEL.en}
          </Button>
          <Button type="submit" variant="contained" disabled={isSpinning}>
            {isSpinning && (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ marginRight: '10px' }}
              />
            )}
            <>{nextButtonLabel}</>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalView;
