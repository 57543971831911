import { BatchFormFields } from '../../types/batchFields';
import { FlagValues } from '../formData';

export const getDefaultBatchFields = (): BatchFormFields => ({
  supplierBatchNumber: '',
  substanceId: '',
  vendorSuppliedId: '',
  expirationDate: null,
  supplier: '',
  catalogueNumber: '',
  flag: FlagValues.NO,
  comments: ''
});
