import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LabActivityKey, labActivities } from '../../../data/labActivities';
import { SEARCH_DESCRIPTION, SEARCH_TITLE } from '../../../i18n/trsl/home';
import { SUBSTANCES } from '../../../routes/consts';
import {
  ActivityCard,
  DISABLED_CARD_SX
} from '../../core/ActivityCard/ActivityCard';
import { FormulationModal } from '../../material-registration/FormulationModal';
import { MaterialBatchModal } from '../../material-registration/MaterialBatchModal';
import { colors } from '../../stylings/colors';

const HomePage: React.FC<{}> = () => {
  const [selectedAction, setSelectedAction] = useState<
    LabActivityKey | undefined
  >(undefined);

  const navigate = useNavigate();

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          maxWidth="1350px"
        >
          {Object.entries(labActivities).map(
            ([key, { title, description, image, status }]) => (
              <Box
                sx={{
                  margin: 2,
                  minWidth: 250,
                  maxWidth: 300,
                  '&:hover': {
                    cursor: 'pointer',
                    boxShadow: `0 4px 10px ${colors.gray}`
                  },
                  ...(status === 'disabled' ? DISABLED_CARD_SX : {})
                }}
                onClick={() =>
                  status === 'enabled'
                    ? setSelectedAction(key as LabActivityKey)
                    : null
                }
                key={title}
              >
                <ActivityCard
                  title={title}
                  description={description}
                  image={image}
                />
              </Box>
            )
          )}
          <Box
            sx={{
              margin: 2,
              minWidth: 250,
              maxWidth: 300,
              '&:hover': {
                cursor: 'pointer',
                boxShadow: `0 4px 10px ${colors.gray}`
              }
            }}
            onClick={() => navigate(`/${SUBSTANCES}`)}
            key={SEARCH_TITLE.en}
          >
            <ActivityCard
              title={SEARCH_TITLE.en}
              description={SEARCH_DESCRIPTION.en}
              image={
                <SearchIcon
                  style={{
                    color: `${colors.darkBlue}`,
                    height: '100px',
                    width: '100px'
                  }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
      <MaterialBatchModal
        selectedAction={selectedAction}
        updateSelectedAction={selectedAction => {
          setSelectedAction(selectedAction);
        }}
      />
      <FormulationModal
        selectedAction={selectedAction}
        updateSelectedAction={selectedAction => {
          setSelectedAction(selectedAction);
        }}
      />
    </>
  );
};

export default HomePage;
