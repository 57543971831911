import axios from 'axios';
import { createApiURL } from '../../configs/envConfig';
import { SUBSTANCE } from '../../routes/consts';
import {
  CodeWithResultSearchSubstance,
  SearchResultField
} from '../all/searchResultsTypes';
import { ApiResponse, ResponseType } from '../api';
import { buildQueryString } from '../utils/buildQueryString';
import { SubstanceDataResponse } from './substanceTypes';

export type GetSubstanceFieldsArgs = {
  substanceFields: SearchResultField<keyof SubstanceDataResponse>[];
};

export const getSubstanceFields = async ({
  substanceFields
}: GetSubstanceFieldsArgs): Promise<SubstanceDataResponse[]> => {
  // Build string using fields
  const queryString =
    buildQueryString<keyof SubstanceDataResponse>(substanceFields);

  const GET_SUBSTANCE_FIELDS_API = createApiURL({
    apiPath: `${SUBSTANCE}?${queryString}`
  });

  const token = localStorage.getItem('jwtToken');

  try {
    if (!token) {
      throw Error('User is not logged in');
    }
    const response: ApiResponse<CodeWithResultSearchSubstance> =
      await axios.get(GET_SUBSTANCE_FIELDS_API, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
    if (response.data.responseType === ResponseType.SUCCESS) {
      return response.data?.result;
    }
    return [];
  } catch (error) {
    throw Error('Error' + error);
  }
};
