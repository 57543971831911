import {
  BaseSubstanceFields,
  CoreAntibody,
  CoreChemicalStock,
  CoreColouredProbe,
  CoreKit,
  CorePlasmid,
  CorePrimer,
  CoreRNA
} from '../components/material-registration/types/formFields';
import {
  COMMENTS_LABEL,
  CREATED_LABEL,
  UPDATED_LABEL
} from '../i18n/trsl/batchRegistration';
import { ID_LABEL, REGISTERED_BY_LABEL } from '../i18n/trsl/home';
import {
  COLOR_LABEL,
  ENTREZ_ID_LABEL,
  KIT_LABEL,
  TARGET_GENE_LABEL,
  ALIAS_LABEL,
  VECTOR_BUILDER_URL_LABEL,
  BACTERIAL_RESISTANCE_LABEL,
  PROMOTER_LABEL,
  MOLWT_LABEL,
  CGRNA_NAME_LABEL,
  SEQUENCE_LABEL,
  ENDONUCLEASE_LABEL,
  SEQUENCE_ID_LABEL,
  DIRECTION_LABEL,
  SPECIES_LABEL,
  SPECIES_RAISED_LABEL,
  FORMULA_LABEL,
  SUBSTANCE_STATE_LABEL,
  PRIMER_CATEGORY_LABEL,
  FLUORESCENT_DYE_LABEL,
  QUENCHER_LABEL,
  SELECTION_RESISTANCE_LABEL,
  NCBI_SUBSTANCE_LABEL,
  KO_A_LABEL,
  RNA_TYPE_LABEL,
  REAGENT_NAME_LABEL,
  HAZARDS_LABEL
} from '../i18n/trsl/reagentForm';

export type Substance = {
  name: string;
  value: string;
};

/* eslint-disable no-unused-vars */
export enum SubstanceCategoryType {
  PLASMID = 'Plasmid',
  COLOURED_PROBE = 'Coloured Probe',
  CHEMICAL_STOCK = 'Chemical Stock',
  CR_RNA = 'crRNA',
  PRIMER = 'Primer',
  KIT = 'Kit',
  SI_RNA = 'siRNA',
  ANTIBODY = 'Antibody',
  RNA = 'RNA'
}

export enum SubstanceCategoryKey {
  PLASMID = 'plasmid',
  COLOURED_PROBE = 'coloured probe',
  CHEMICAL_STOCK = 'chemical stock',
  PRIMER = 'primer',
  KIT = 'kit',
  ANTIBODY = 'antibody',
  RNA = 'RNA'
}

export enum RNATypeKeys {
  CR_RNA = 'crRNA',
  SI_RNA = 'siRNA',
  SG_RNA = 'sgRNA'
}
/* eslint-enable no-unused-vars */

export const substances: {
  [key: string]: SubstanceCategoryType;
} = {
  [SubstanceCategoryKey.PLASMID]: SubstanceCategoryType.PLASMID,
  [SubstanceCategoryKey.COLOURED_PROBE]: SubstanceCategoryType.COLOURED_PROBE,
  [SubstanceCategoryKey.CHEMICAL_STOCK]: SubstanceCategoryType.CHEMICAL_STOCK,
  [SubstanceCategoryKey.PRIMER]: SubstanceCategoryType.PRIMER,
  [SubstanceCategoryKey.KIT]: SubstanceCategoryType.KIT,
  [SubstanceCategoryKey.ANTIBODY]: SubstanceCategoryType.ANTIBODY,
  [SubstanceCategoryKey.RNA]: SubstanceCategoryType.RNA
};

const rnaTypes: { [key: string]: RNATypeKeys } = {
  [RNATypeKeys.CR_RNA]: RNATypeKeys.CR_RNA,
  [RNATypeKeys.SI_RNA]: RNATypeKeys.SI_RNA,
  [RNATypeKeys.SG_RNA]: RNATypeKeys.SG_RNA
};

const antibodyType: { [key: string]: string } = { antibodies: 'Antibodies' };

export const substanceTypes = { ...substances, ...rnaTypes, ...antibodyType };

type AllCoreFormFields = CoreKit &
  CorePrimer &
  CorePlasmid &
  CoreAntibody &
  CoreColouredProbe &
  CoreRNA &
  CoreChemicalStock &
  BaseSubstanceFields & {
    id: string;
    created: string;
    registeredBy: string;
    updated: string;
  };

type AllFormKeys = keyof AllCoreFormFields;
type SubstanceKeysLabels = {
  /* eslint-disable no-unused-vars */
  [key in AllFormKeys]: string;
};

export const substanceFieldsKeys: SubstanceKeysLabels = {
  id: ID_LABEL.en,
  created: CREATED_LABEL.en,
  registeredBy: REGISTERED_BY_LABEL.en,
  comments: COMMENTS_LABEL.en,
  updated: UPDATED_LABEL.en,
  kit: KIT_LABEL.en,
  targetGene: TARGET_GENE_LABEL.en,
  aliasName: ALIAS_LABEL.en,
  vectorBuilderUrl: VECTOR_BUILDER_URL_LABEL.en,
  bacterialResistance: BACTERIAL_RESISTANCE_LABEL.en,
  promoter: PROMOTER_LABEL.en,
  color: COLOR_LABEL.en,
  molWt: MOLWT_LABEL.en,
  cgRNAName: CGRNA_NAME_LABEL.en,
  sequence: SEQUENCE_LABEL.en,
  endonuclease: ENDONUCLEASE_LABEL.en,
  sequenceId: SEQUENCE_ID_LABEL.en,
  entrezId: ENTREZ_ID_LABEL.en,
  direction: DIRECTION_LABEL.en,
  species: SPECIES_LABEL.en,
  speciesRaised: SPECIES_RAISED_LABEL.en,
  formula: FORMULA_LABEL.en,
  substanceState: SUBSTANCE_STATE_LABEL.en,
  primerCategory: PRIMER_CATEGORY_LABEL.en,
  fluorescentDye: FLUORESCENT_DYE_LABEL.en,
  quencher: QUENCHER_LABEL.en,
  selectionResistance: SELECTION_RESISTANCE_LABEL.en,
  ncbiSubstanceId: NCBI_SUBSTANCE_LABEL.en,
  koa: KO_A_LABEL.en,
  rnaType: RNA_TYPE_LABEL.en,
  reagentName: REAGENT_NAME_LABEL.en,
  hazards: HAZARDS_LABEL.en
};
