import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';

import { SubstanceDataResponse } from '../../../../apis/substance/substanceTypes';
import {
  substanceFieldsKeys,
  substanceTypes
} from '../../../../data/substances';
import { colors } from '../../../stylings/colors';
import SubstanceActionButtons from './SubstanceActionButtons';

interface SubstanceTableRowProps {
  row: SubstanceDataResponse;
  // eslint-disable-next-line no-unused-vars
  handleSelectedRow: (row: SubstanceDataResponse) => void;
  handleViewBatches: () => void;
  handleAddBatch: () => void;
  defaultExpanded?: boolean;
}

const SubstanceTableRow: React.FC<SubstanceTableRowProps> = ({
  row,
  handleSelectedRow,
  handleViewBatches,
  handleAddBatch,
  defaultExpanded = false
}) => {
  const [isExpandedCell, setIsExpandedCell] = useState(defaultExpanded);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsExpandedCell(!isExpandedCell)}
          >
            {isExpandedCell ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.substanceName}
        </TableCell>
        <TableCell>{substanceTypes[row.substanceCategory]}</TableCell>
        <TableCell align="center">{row.batches?.length ?? 0}</TableCell>
        <TableCell align="center">
          <SubstanceActionButtons
            handleViewBatch={() => {
              handleViewBatches();
              handleSelectedRow(row);
            }}
            handleAddBatch={() => {
              handleAddBatch();
              handleSelectedRow(row);
            }}
            row={row}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: colors.veryLightBlue
          }}
          colSpan={6}
        >
          <Collapse in={isExpandedCell} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {Object.entries(substanceFieldsKeys).map(
                ([key, label]) =>
                  row[key as keyof SubstanceDataResponse] && (
                    <div key={key}>
                      <Typography
                        variant="subtitle1"
                        component="span"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {`${label}:`}
                      </Typography>
                      <Typography variant="subtitle1" component="span">
                        {` ${row[key as keyof SubstanceDataResponse]}`}
                      </Typography>
                    </div>
                  )
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SubstanceTableRow;
