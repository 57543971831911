import { RNATypeKeys } from '../../../data/substances';
import { NO_LABEL, YES_LABEL } from '../../../i18n/trsl/batchRegistration';
import { NA_LABEL } from '../../../i18n/trsl/materialRegistration';
import {
  ACTIVATION_LABEL,
  BACTERIAL_CULTURE_LABEL,
  CHICKEN_LABEL,
  COMBUSTIBLE_LABEL,
  CORROSIVE_LABEL,
  DONKEY_LABEL,
  ENVIRONMENTAL_LABEL,
  FLAMMABLE_LABEL,
  FORMULATION_LABEL,
  FORWARD_LABEL,
  GAS_LABEL,
  GLYCEROL_LABEL,
  GOAT_LABEL,
  HUMAN_LABEL,
  KNOCKOUT_LABEL,
  LIQUID_LABEL,
  MOUSE_LABEL,
  NON_HAZARD_LABEL,
  OTHER_LABEL,
  PORCINE_LABEL,
  POWDER_LABEL,
  PRIMER_LABEL,
  RABBIT_LABEL,
  RAT_LABEL,
  RECONSTITUTED_LABEL,
  REVERSE_LABEL,
  SET_LABEL,
  SOLID_LABEL,
  STREPTOCOCCUS_PYOGENES_LABEL,
  TAQMAN_LABEL,
  TOXIC_LABEL
} from '../../../i18n/trsl/reagentForm';

export type DataSelector = {
  label: string;
  value: string;
};

/* eslint-disable no-unused-vars */
export enum HazardsValues {
  NON_HAZARD = 'non-hazard',
  FLAMMABLE = 'flammable',
  TOXIC = 'toxic',
  COMBUSTIBLE = 'combustible',
  ENVIRONMENTAL = 'environmental',
  CORROSIVE = 'corrosive'
}

export enum SubstanceStateValues {
  BACTERIAL_CULTURE = 'bacterial-culture',
  GAS = 'gas',
  GLYCEROL = 'glycerol',
  LIQUID = 'liquid',
  OTHER = 'other',
  POWDER = 'powder',
  RECONSTITUTED = 'reconstituted',
  SOLID = 'solid'
}

export enum KoaValues {
  KNOCKOUT = 'knockout',
  ACTIVATION = 'activation'
}

export enum SpeciesValues {
  HUMAN = 'human',
  MOUSE = 'mouse',
  RABBIT = 'rabbit',
  GOAT = 'goat',
  RAT = 'rat',
  DONKEY = 'donkey',
  PORCINE = 'porcine',
  CHICKEN = 'chicken',
  STREPTOCOCCUS_PYOGENES = 'streptococcus-pyogenes'
}

export enum PrimerCategoryValues {
  PRIMER = 'primer',
  TAQMAN = 'taqman'
}

export enum DirectionValues {
  FORWARD = 'forward',
  REVERSE = 'reverse',
  NA = 'n/a'
}

export enum FormulationTypeValue {
  FORMULATION = 'formulation',
  SET = 'set'
}
/* eslint-disable no-unused-vars */

// Data
export const hazardsData: DataSelector[] = [
  { label: NON_HAZARD_LABEL.en, value: HazardsValues.NON_HAZARD },
  { label: FLAMMABLE_LABEL.en, value: HazardsValues.FLAMMABLE },
  { label: TOXIC_LABEL.en, value: HazardsValues.TOXIC },
  { label: CORROSIVE_LABEL.en, value: HazardsValues.CORROSIVE },
  { label: COMBUSTIBLE_LABEL.en, value: HazardsValues.COMBUSTIBLE },
  { label: ENVIRONMENTAL_LABEL.en, value: HazardsValues.ENVIRONMENTAL },
  { label: CORROSIVE_LABEL.en, value: HazardsValues.CORROSIVE }
];

export const substanceStateData: DataSelector[] = [
  {
    label: BACTERIAL_CULTURE_LABEL.en,
    value: SubstanceStateValues.BACTERIAL_CULTURE
  },
  { label: GAS_LABEL.en, value: SubstanceStateValues.GAS },
  { label: GLYCEROL_LABEL.en, value: SubstanceStateValues.GLYCEROL },
  { label: LIQUID_LABEL.en, value: SubstanceStateValues.LIQUID },
  { label: POWDER_LABEL.en, value: SubstanceStateValues.POWDER },
  { label: RECONSTITUTED_LABEL.en, value: SubstanceStateValues.RECONSTITUTED },
  { label: SOLID_LABEL.en, value: SubstanceStateValues.SOLID },
  { label: OTHER_LABEL.en, value: SubstanceStateValues.OTHER }
];

export const koaData: DataSelector[] = [
  { label: KNOCKOUT_LABEL.en, value: KoaValues.KNOCKOUT },
  { label: ACTIVATION_LABEL.en, value: KoaValues.ACTIVATION }
];

export const speciesData: DataSelector[] = [
  { label: HUMAN_LABEL.en, value: SpeciesValues.HUMAN },
  { label: MOUSE_LABEL.en, value: SpeciesValues.MOUSE },
  { label: RABBIT_LABEL.en, value: SpeciesValues.RABBIT },
  { label: GOAT_LABEL.en, value: SpeciesValues.GOAT },
  { label: RAT_LABEL.en, value: SpeciesValues.RAT },
  { label: DONKEY_LABEL.en, value: SpeciesValues.DONKEY },
  { label: PORCINE_LABEL.en, value: SpeciesValues.PORCINE },
  { label: STREPTOCOCCUS_PYOGENES_LABEL.en, value: SpeciesValues.CHICKEN }
];

export const speciesRaisedData: DataSelector[] = [
  { label: HUMAN_LABEL.en, value: SpeciesValues.HUMAN },
  { label: MOUSE_LABEL.en, value: SpeciesValues.MOUSE },
  { label: RABBIT_LABEL.en, value: SpeciesValues.RABBIT },
  { label: GOAT_LABEL.en, value: SpeciesValues.GOAT },
  { label: RAT_LABEL.en, value: SpeciesValues.RAT },
  { label: DONKEY_LABEL.en, value: SpeciesValues.DONKEY },
  { label: PORCINE_LABEL.en, value: SpeciesValues.PORCINE },
  { label: CHICKEN_LABEL.en, value: SpeciesValues.CHICKEN }
];

export const primerCategoryData: DataSelector[] = [
  { label: PRIMER_LABEL.en, value: PrimerCategoryValues.PRIMER },
  { label: TAQMAN_LABEL.en, value: PrimerCategoryValues.TAQMAN }
];

export const directionData: DataSelector[] = [
  { label: FORWARD_LABEL.en, value: DirectionValues.FORWARD },
  { label: REVERSE_LABEL.en, value: DirectionValues.REVERSE },
  { label: NA_LABEL.en, value: DirectionValues.NA }
];

export const rnaTypeData: DataSelector[] = [
  { label: RNATypeKeys.SG_RNA, value: RNATypeKeys.SG_RNA },
  { label: RNATypeKeys.SI_RNA, value: RNATypeKeys.SI_RNA },
  { label: RNATypeKeys.CR_RNA, value: RNATypeKeys.CR_RNA }
];

// BATCH

export enum FlagValues {
  YES = 'yes',
  NO = 'no'
}

export const flagData: DataSelector[] = [
  { label: YES_LABEL.en, value: FlagValues.YES },
  { label: NO_LABEL.en, value: FlagValues.NO }
];

// FORMULATION

export const formulationTypeData: DataSelector[] = [
  { label: FORMULATION_LABEL.en, value: FormulationTypeValue.FORMULATION },
  { label: SET_LABEL.en, value: FormulationTypeValue.SET }
];
