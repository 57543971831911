import React, { useMemo, useState } from 'react';
import {
  Control,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { LabActivityKey } from '../../data/labActivities';
import useToastDetails, { TOAST_TIMER } from '../../hooks/useToast';
import { ADD_SIRNA_LABEL } from '../../i18n/trsl/formulation';
import {
  ACTION_PERFORMED_TITLE,
  ADD_BATCH_TITLE,
  CONTENT_COMING_SOON_LABEL,
  NEW_FORMULATION_TITLE,
  NEXT_LABEL
} from '../../i18n/trsl/home';

import {
  ApiResponseData,
  ResponseType,
  SuccessResponseCode
} from '../../apis/api';
import { registerBatch } from '../../apis/batch/batch';
import { registerFormulation } from '../../apis/formulation/formulation';
import { FORMULATION_BATCH_FORM_STEPS } from '../../data/formSteps';
import ProgressStepper from '../core/ProgressStepper/ProgressStepper';
import { SnackbarAlert } from '../core/SnackbarAlert/SnackbarAlert';
import ModalView from '../views/ModalView/ModalView';
import BatchFormulationForm from './forms/BatchFormulationForm/BatchFormulationForm';
import SirnaSetForm from './forms/SirnaSetForm/SirnaSetForm';
import {
  getDefaultBatchFormulationFields,
  getDefaultSirnaFormulationFields
} from './forms/formUtils/formulationUtils';
import {
  ArrayFormulationHooks,
  FormulationBatchFormFields,
  FormulationSirnaFormFields
} from './types/formulationFields';

export type SelectedAction = LabActivityKey | undefined;

interface FormulationModalProps {
  selectedAction: SelectedAction;
  // eslint-disable-next-line no-unused-vars
  updateSelectedAction: (selectedAction: SelectedAction) => void;
}

export const FormulationModal: React.FC<FormulationModalProps> = ({
  selectedAction,
  updateSelectedAction
}) => {
  const snackbarDetails = useToastDetails();
  const { toastDetails, updateToast, clearToast } = snackbarDetails;
  const [isSpinning, setIsSpinning] = useState(false);
  const [formulationIds, setFormulationIds] = useState<string[]>([]);

  const {
    handleSubmit: handleFormulationSubmit,
    control: controlSirna,
    reset: resetSirna
  } = useForm<FormulationSirnaFormFields>({
    defaultValues: getDefaultSirnaFormulationFields(),
    mode: 'onChange'
  });

  const { replace } = useFieldArray({
    control: controlSirna,
    name: 'substanceComponents'
  });

  const {
    handleSubmit: handleBatchSubmit,
    control: controlBatch,
    reset: resetBatch
  } = useForm<FormulationBatchFormFields>({
    defaultValues: getDefaultBatchFormulationFields(),
    mode: 'onChange'
  });

  const handleModalClose = (reason?: unknown) => {
    if (reason !== 'backdropClick') {
      updateSelectedAction(undefined);
      resetBatch();
      resetSirna();
    }
  };

  const onSirnaFormulation: SubmitHandler<any> = (
    // eslint-disable-next-line no-unused-vars
    data: FormulationSirnaFormFields
  ) => {
    const addFormulation = async () => {
      setIsSpinning(true);
      const newFormulation: ApiResponseData<any> = await registerFormulation({
        formData: data
      });
      if (newFormulation.code === SuccessResponseCode.SUBSTANCE_REGISTERED) {
        setFormulationIds([newFormulation?.result?.id]);
      }
      updateToast({
        responseType: newFormulation?.responseType as ResponseType,
        message: newFormulation?.message
      });
      setIsSpinning(false);
      if (
        newFormulation.code !== SuccessResponseCode.SUBSTANCE_NOT_FOUND &&
        newFormulation.responseType !== ResponseType.ERROR
      ) {
        updateSelectedAction(LabActivityKey.BATCH_FORMULATION);
      }
    };
    addFormulation();
  };

  const onBatchFormulation: SubmitHandler<any> = (
    data: FormulationBatchFormFields
  ) => {
    const addBatch = async () => {
      setIsSpinning(true);
      const batchResponses = await Promise.all(
        formulationIds.map(
          async (id: string) =>
            await registerBatch({ ...data, substanceId: id })
        )
      );
      updateToast({
        responseType: batchResponses?.[0]?.responseType as ResponseType,
        message: batchResponses?.[0]?.message
      });
      setIsSpinning(false);
      if (batchResponses?.[0]?.code === SuccessResponseCode.BATCH_REGISTERED) {
        handleModalClose();
        updateSelectedAction(undefined);
      }
    };
    addBatch();
  };

  const isOpen = useMemo(
    () =>
      selectedAction === LabActivityKey.SIRNA_FORMULATION ||
      selectedAction === LabActivityKey.BATCH_FORMULATION,
    [selectedAction]
  );

  return (
    <>
      <ModalView
        isOpen={!!isOpen}
        onClose={(reason: unknown) => handleModalClose(reason)}
        handleSubmit={
          selectedAction === LabActivityKey.SIRNA_FORMULATION
            ? handleFormulationSubmit(onSirnaFormulation)
            : handleBatchSubmit(onBatchFormulation)
        }
        modalTitle={NEW_FORMULATION_TITLE.en}
        isSpinning={isSpinning}
        nextButtonLabel={getModalLabel(selectedAction)}
      >
        <ProgressStepper
          activeStep={getActiveStep(selectedAction)}
          optionalStep={2}
          steps={FORMULATION_BATCH_FORM_STEPS}
        />
        <FormulationContent
          selectedAction={selectedAction}
          controls={{ sirna: controlSirna, batch: controlBatch }}
          arrayHooks={{ replace }}
          formulationIds={formulationIds}
        />
      </ModalView>
      <SnackbarAlert
        isOpen={!!toastDetails?.message}
        message={toastDetails?.message ?? ACTION_PERFORMED_TITLE.en}
        alertType={toastDetails?.responseType ?? 'info'}
        onClose={() => clearToast()}
        timer={TOAST_TIMER}
      />
    </>
  );
};

const getModalLabel = (selectedAction: SelectedAction): string => {
  switch (selectedAction) {
    case LabActivityKey.SIRNA_FORMULATION:
      return ADD_SIRNA_LABEL.en;
    case LabActivityKey.BATCH_FORMULATION:
      return ADD_BATCH_TITLE.en;
    default:
      return NEXT_LABEL.en;
  }
};

const getActiveStep = (selectedAction: SelectedAction): number => {
  switch (selectedAction) {
    case LabActivityKey.SIRNA_FORMULATION:
      return 0;
    case LabActivityKey.BATCH_FORMULATION:
      return 1;
    default:
      return 0;
  }
};

interface FormulationContentProps {
  selectedAction: SelectedAction;
  controls: {
    sirna: Control<FormulationSirnaFormFields>;
    batch: Control<FormulationBatchFormFields>;
  };
  arrayHooks: ArrayFormulationHooks;
  formulationIds: string[];
}

const FormulationContent = ({
  selectedAction,
  controls,
  arrayHooks,
  formulationIds
}: FormulationContentProps) => {
  switch (selectedAction) {
    case LabActivityKey.SIRNA_FORMULATION:
      return <SirnaSetForm control={controls.sirna} arrayHooks={arrayHooks} />;
    case LabActivityKey.BATCH_FORMULATION:
      return (
        <BatchFormulationForm
          control={controls.batch}
          formulationIds={formulationIds}
        />
      );
    default:
      return <div>{CONTENT_COMING_SOON_LABEL.en}</div>;
  }
};
