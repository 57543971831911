import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SubstanceDataResponse } from '../../../../apis/substance/substanceTypes';
import {
  ADD_BATCH_LABEL,
  VIEW_BATCHES_LABEL,
  VIEW_BATCH_LABEL
} from '../../../../i18n/trsl/search';
import { colors } from '../../../stylings/colors';

interface SubstanceActionButtonsProps {
  handleViewBatch: () => void;
  handleAddBatch: () => void;
  row: SubstanceDataResponse;
}

const SubstanceActionButtons = ({
  handleViewBatch,
  handleAddBatch,
  row
}: SubstanceActionButtonsProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px'
    }}
  >
    {!!row.batches && row.batches?.length > 0 && (
      <ViewBatchButton
        handleClick={handleViewBatch}
        isSingleBatch={row.batches?.length === 1}
      />
    )}
    <AddBatchButton handleAdd={handleAddBatch} />
  </Box>
);

interface ViewBatchButtonProps {
  isSingleBatch: boolean;
  handleClick: () => void;
}

const ViewBatchButton = ({
  isSingleBatch,
  handleClick
}: ViewBatchButtonProps) => (
  <IconButton
    data-testid="view-button"
    onClick={handleClick}
    sx={{
      width: '150px',
      border: `2px solid ${colors.primaryBlue}`,
      color: colors.primaryBlue,
      backgroundColor: colors.nearWhite,
      borderRadius: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: colors.primaryBlue,
        color: colors.white,
        '& svg': {
          color: colors.white
        }
      },
      '& svg': {
        color: `${colors.primaryBlue}`,
        marginRight: '5px'
      }
    }}
  >
    <VisibilityIcon />
    <span style={{ fontSize: '0.6em' }}>
      {isSingleBatch ? VIEW_BATCH_LABEL.en : VIEW_BATCHES_LABEL.en}
    </span>
  </IconButton>
);

interface AddBatchButtonProps {
  handleAdd: () => void;
}

const AddBatchButton = ({ handleAdd }: AddBatchButtonProps) => (
  <IconButton
    data-testid="add-button"
    onClick={handleAdd}
    sx={{
      width: '150px',
      border: `2px solid ${colors.primaryBlue}`,
      color: colors.primaryBlue,
      backgroundColor: colors.nearWhite,
      borderRadius: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: colors.primaryBlue,
        color: colors.white,
        '& svg': {
          color: colors.white
        }
      },
      '& svg': {
        color: `${colors.primaryBlue}`,
        marginRight: '5px'
      }
    }}
  >
    <AddCircleOutlineIcon />
    <span style={{ fontSize: '0.6em' }}>{ADD_BATCH_LABEL.en}</span>
  </IconButton>
);

export default SubstanceActionButtons;
