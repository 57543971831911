import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ApiResponseData,
  ResponseType,
  SuccessResponseCode
} from '../../../apis/api';
import { registerBatch } from '../../../apis/batch/batch';
import { SubstanceDataResponse } from '../../../apis/substance/substanceTypes';
import { SUBSTANCE_BATCH_FORM_STEPS } from '../../../data/formSteps';
import useToastDetails, { TOAST_TIMER } from '../../../hooks/useToast';
import {
  ACTION_PERFORMED_TITLE,
  ADD_BATCH_TITLE,
  BATCH_REGISTRATION_TITLE
} from '../../../i18n/trsl/home';
import ProgressStepper from '../../core/ProgressStepper/ProgressStepper';
import { SnackbarAlert } from '../../core/SnackbarAlert/SnackbarAlert';
import BatchRegistrationWrapper from '../../material-registration/BatchRegistrationWrapper/BatchRegistrationWrapper';
import { getDefaultBatchFields } from '../../material-registration/forms/formUtils/batchUtils';
import { BatchFormFields } from '../../material-registration/types/batchFields';
import ModalView from '../../views/ModalView/ModalView';
import BatchesList from '../../views/SearchPage/BatchDetails/BatchDialog';
import { SubstanceTable } from '../../views/SearchPage/SearchTable/SubstanceTable';

interface SubstanceTableWithActionsProps {
  substanceDetails: SubstanceDataResponse[] | undefined;
  defaultExpanded?: boolean;
  hidePagination?: boolean;
}
export const SubstanceTableWithActions = ({
  substanceDetails,
  defaultExpanded,
  hidePagination
}: SubstanceTableWithActionsProps) => {
  const snackbarDetails = useToastDetails();

  const { toastDetails, updateToast, clearToast } = snackbarDetails;

  const [selectedRow, setSelectedRow] = useState<
    SubstanceDataResponse | undefined
  >(undefined);
  const [isAddBatchOpen, setIsAddBatchOpen] = useState(false);
  const [isViewBatchDialog, setIsViewBatchDialog] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const {
    control: controlBatch,
    handleSubmit: handleSubmitBatch,
    reset: resetBatch,
    setValue: setBatchValue
  } = useForm<BatchFormFields>({
    defaultValues: getDefaultBatchFields()
  });

  const handleModalClose = () => {
    setIsAddBatchOpen(false);
    setIsViewBatchDialog(false);
    setSelectedRow(undefined);
    resetBatch();
  };

  useEffect(() => {
    selectedRow ? setBatchValue('substanceId', selectedRow?.id) : null;
  }, [setBatchValue, selectedRow]);

  const handleBatch: SubmitHandler<any> = (data: BatchFormFields) => {
    setIsSpinning(true);
    const addBatch = async () => {
      const newBatch: ApiResponseData<any> = await registerBatch(data);
      updateToast({
        responseType: newBatch?.responseType as ResponseType,
        message: newBatch?.message
      });
      setIsSpinning(false);
      if (newBatch.code !== SuccessResponseCode.SUBSTANCE_NOT_FOUND) {
        handleModalClose();
      }
    };
    addBatch();
  };

  return (
    <>
      <SubstanceTable
        rows={substanceDetails}
        handleSelectedRow={selectedRow => setSelectedRow(selectedRow)}
        handleViewBatches={() => setIsViewBatchDialog(true)}
        handleAddBatch={() => setIsAddBatchOpen(true)}
        defaultExpanded={defaultExpanded}
        hidePagination={hidePagination}
      />
      <BatchesList
        open={isViewBatchDialog}
        closeDialog={handleModalClose}
        onClose={handleModalClose}
        batches={selectedRow?.batches || []}
      />
      {isAddBatchOpen && (
        <ModalView
          isOpen={isAddBatchOpen}
          onClose={handleModalClose}
          handleSubmit={handleSubmitBatch(handleBatch)}
          modalTitle={BATCH_REGISTRATION_TITLE.en}
          isSpinning={isSpinning}
          nextButtonLabel={ADD_BATCH_TITLE.en}
        >
          <ProgressStepper
            activeStep={1}
            optionalStep={1}
            steps={SUBSTANCE_BATCH_FORM_STEPS}
          />
          <BatchRegistrationWrapper control={controlBatch} />
        </ModalView>
      )}
      <SnackbarAlert
        isOpen={!!toastDetails?.message}
        message={toastDetails?.message ?? ACTION_PERFORMED_TITLE.en}
        alertType={toastDetails?.responseType ?? 'info'}
        onClose={() => clearToast()}
        timer={TOAST_TIMER}
      />
    </>
  );
};
