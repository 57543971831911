import SearchIcon from '@mui/icons-material/Search';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ADVANCED_SEARCH_LABEL,
  BASIC_SEARCH_LABEL,
  BASIC_SEARCH_PLACEHOLDER
} from '../../../../i18n/trsl/home';
import { colors } from '../../../stylings/colors';
import AdvancedSearchWrapper from './AdvancedSearchWrapper';
import { TextBoxFilter } from './TextBoxFilter';

/* eslint-disable no-unused-vars */
enum SearchType {
  BASIC = 'basic',
  ADVANCED = 'advanced'
}
/* eslint-disable no-unused-vars */

interface SearchWrapperBarProps {
  /* eslint-disable no-unused-vars */
  handleFilterItem: (data: string) => void;
  /* eslint-disable no-unused-vars */
  handleFilterFields: (data: Record<string, string>) => void;
}

const SearchWrapperBar = ({
  handleFilterItem,
  handleFilterFields
}: SearchWrapperBarProps) => {
  const [searchType, setSearchType] = useState<SearchType>(SearchType.BASIC);

  useEffect(() => {
    handleFilterFields({});
    handleFilterItem('');
  }, [searchType]);

  return (
    <Stack
      direction="row"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        px="20px"
        width="70%"
      >
        {searchType === SearchType.BASIC ? (
          <TextBoxFilter
            handleUpdate={data => handleFilterItem(data)}
            label={BASIC_SEARCH_PLACEHOLDER.en}
            icon={<SearchIcon style={{ cursor: 'default' }} />}
            sx={{
              minWidth: '300px',
              borderRadius: '4px',
              bgcolor: 'transparent',
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: colors.black
                },
              flexBasis: { xs: '100%', md: '70%' }
            }}
          />
        ) : (
          <AdvancedSearchWrapper
            handleSearch={data => handleFilterFields(data)}
          />
        )}
        <SearchToggle
          searchType={searchType}
          handleToggle={() =>
            setSearchType(prevState =>
              prevState === SearchType.BASIC
                ? SearchType.ADVANCED
                : SearchType.BASIC
            )
          }
        />
      </Box>
    </Stack>
  );
};

export default SearchWrapperBar;

interface SearchToggleProps {
  searchType: SearchType;
  handleToggle: () => void;
}

const SearchToggle = ({ searchType, handleToggle }: SearchToggleProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      margin: '0 20px',
      flexBasis: { xs: '100%', md: '15%' },
      justifyContent: 'center',
      padding: '20px'
    }}
  >
    <Typography variant="body1">{BASIC_SEARCH_LABEL.en}</Typography>
    <Switch
      checked={searchType === SearchType.ADVANCED}
      onChange={handleToggle}
      color="primary"
    />
    <Typography variant="body1">{ADVANCED_SEARCH_LABEL.en}</Typography>
  </Box>
);
