import { API_PROD_URL, API_TEST_URL, PRODUCTION_HOST } from './configVars';

export type ApiUrl = {
  apiPath: string;
};

export const createApiURL = ({ apiPath }: ApiUrl) => {
  const isProduction = window?.location?.host === PRODUCTION_HOST;
  const BASE_URL = isProduction ? API_PROD_URL : API_TEST_URL;
  const ENV_TYPE = isProduction ? ENV_TYPE_PROD : ENV_TYPE_TEST;
  return `${BASE_URL}/${ENV_TYPE}/${apiPath}`;
};

export const ENV_TYPE_TEST = 'TEST';
export const ENV_TYPE_PROD = 'PROD';
export const ENV_TYPE_REFACTOR = 'refactor';
export const SUBSTANCE_PATH = 'substance';
export const BATCH_PATH = 'batch';
export const FORMULATION_PATH = 'formulation';
export const ALL_PATH = 'all';
