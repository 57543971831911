import axios from 'axios';
import { SUBSTANCE_PATH, createApiURL } from '../../configs/envConfig';
import { ApiResponse, ResponseType } from '../api';
import {
  CodeWithResultSubstanceFound,
  SubstanceDataResponse
} from './substanceTypes';

export const getAllSubstances = async (): Promise<SubstanceDataResponse[]> => {
  const SUBSTANCE_API = createApiURL({
    apiPath: `${SUBSTANCE_PATH}`
  });

  const token = localStorage.getItem('jwtToken');

  try {
    if (!token) {
      throw Error('User is not logged in');
    }
    const response: ApiResponse<CodeWithResultSubstanceFound> = await axios.get(
      SUBSTANCE_API,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.responseType === ResponseType.SUCCESS) {
      return response.data?.result;
    }
    return [];
  } catch (error) {
    throw Error('Error' + error);
  }
};
