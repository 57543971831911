import trsl from '../i18n/index';

const _ = require('lodash');
const { NA_LABEL } = trsl.materialRegistration;

export const lowercaseObjValues = (obj: Record<string, any>) =>
  _.mapValues(obj, (value: string) =>
    _.isString(value) ? value.toLowerCase() : value
  );

export const textDisplayOrNA = (text: string) =>
  _.isEmpty(text) ? NA_LABEL.en : text;

export const getBaseURL = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};
