import { i18n } from '../i18nType';

export const ACTION_PERFORMED_TITLE: i18n = {
  en: 'Action performed.'
};
export const BATCH_REGISTRATION_BUTTON: i18n = { en: 'Create Batch' };
export const BATCH_REGISTRATION_DESCRIPTION: i18n = {
  en: 'Create a new batch for an existing material, requires material ID'
};
export const BATCH_REGISTRATION_TITLE: i18n = { en: 'Batch Registration' };
export const BULK_UPLOAD_BUTTON: i18n = { en: 'Upload CSV' };
export const SEARCH_BUTTON: i18n = { en: 'Search' };
export const BULK_UPLOAD_DESCRIPTION: i18n = {
  en: 'COMING SOON: This feature is coming soon!'
  // future: 'Upload a CSV file to register multiple materials, including batches'
};
export const SEARCH_DESCRIPTION: i18n = {
  en: 'Search for materials, batches, and more.'
};
export const BULK_UPLOAD_TITLE: i18n = { en: 'Bulk Upload' };
export const SEARCH_TITLE: i18n = { en: 'Search' };
export const NEW_FORMULATION_BUTTON: i18n = { en: 'Create Formulation' };
export const NEW_FORMULATION_DESCRIPTION: i18n = {
  en: 'Register formulation, containing two or more materials!'
};
export const NEW_FORMULATION_TITLE: i18n = { en: 'New Formulation' };
export const MATERIAL_REGISTRATION_BUTTON: i18n = { en: 'Register Material' };
export const MATERIAL_REGISTRATION_DESCRIPTION: i18n = {
  en: 'Complete the form to register a new single material'
};
export const MATERIAL_REGISTRATION_TITLE: i18n = {
  en: 'Material Registration'
};
export const BASIC_SEARCH_PLACEHOLDER: i18n = {
  en: 'Search by Name, Target Gene or Catalogue Number'
};

export const FILTER_RESULTS_PLACEHOLDER: i18n = {
  en: 'Filter results'
};

export const PLEASE_SELECT_FILTERS_PLACEHOLDER: i18n = {
  en: 'Please select filters'
};
export const ENTER_FILTER_VALUE_PLACEHOLDER: i18n = {
  en: 'Enter filter value'
};
export const SEARCH_CHOOSE_FILTER_PLACEHOLDER: i18n = {
  en: 'Choose filter'
};
export const SEARCH_LABEL: i18n = {
  en: 'Search'
};
export const BASIC_SEARCH_LABEL: i18n = {
  en: 'Basic'
};
export const ADVANCED_SEARCH_LABEL: i18n = {
  en: 'Advanced'
};
export const ADD_FILTER_LABEL: i18n = {
  en: 'Add Filter'
};
export const ID_LABEL: i18n = { en: 'ID' };
export const NAME_LABEL: i18n = { en: 'Name' };
export const SUBSTANCE_CATEGORY_LABEL: i18n = { en: 'Type' };
export const SUPPLIER_LABEL: i18n = { en: 'Supplier' };
export const REGISTERED_BY_LABEL: i18n = { en: 'Registered By' };
export const CREATED_LABEL: i18n = { en: 'Registered On' };
export const COLOR_LABEL: i18n = { en: 'Color' };
export const ENTREZ_ID_LABEL: i18n = { en: 'Entrez ID' };
export const LOT_NUMBER_LABEL: i18n = { en: 'Lot Number' };
export const TARGET_LABEL: i18n = { en: 'Target' };
export const CATALOGUE_NUMBER_LABEL: i18n = { en: 'Catalogue Number' };
export const ADD_SUBSTANCE_TITLE: i18n = {
  en: 'Add Substance'
};
export const ADD_BATCH_TITLE: i18n = {
  en: 'Add Batch'
};

export const NEXT_LABEL: i18n = { en: 'Next' };

export const CONTENT_COMING_SOON_LABEL = { en: 'Content coming soon!' };

export const LOADING: i18n = { en: 'Loading...' };
export const NO_RESULTS_LABEL: i18n = { en: 'No results' };
