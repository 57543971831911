import { Box, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SubstanceCategoryKey } from '../../../../data/substances';
import {
  ALIAS_LABEL,
  COMMENTS_LABEL,
  HAZARDS_LABEL,
  KIT_LABEL,
  REAGENT_NAME_LABEL,
  TARGET_GENE_LABEL
} from '../../../../i18n/trsl/reagentForm';
import {
  APHANUM_SPACE_DASH_DOT_LABEL,
  APHANUM_SPACE_DASH_LABEL,
  REQUIRED_LABEL
} from '../../../../i18n/trsl/validation';
import {
  APHANUM_SPACE_DASH_DOT_REGEX,
  APHANUM_SPACE_DASH_REGEX
} from '../../../../utils/regex';
import Dropdown from '../../../core/Dropdown/Dropdown';
import { SubstanceFormFields } from '../../types/formFields';
import { hazardsData } from '../formData';

interface KitFormProps {
  control: Control<SubstanceFormFields>;
}

const KitForm: React.FC<KitFormProps> = ({ control }: KitFormProps) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
      <Controller
        name={`${SubstanceCategoryKey.KIT}.reagentName`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_DOT_REGEX,
            message: APHANUM_SPACE_DASH_DOT_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={REAGENT_NAME_LABEL.en}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            margin="normal"
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.KIT}.targetGene`}
        control={control}
        rules={{
          required: REQUIRED_LABEL.en,
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={TARGET_GENE_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Dropdown
        control={control}
        inputLabel={HAZARDS_LABEL.en}
        controlName={`${SubstanceCategoryKey.KIT}.hazards`}
        data={hazardsData}
        fullWidth
      />
      <Controller
        name={`${SubstanceCategoryKey.KIT}.kit`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={KIT_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.KIT}.aliasName`}
        control={control}
        rules={{
          pattern: {
            value: APHANUM_SPACE_DASH_REGEX,
            message: APHANUM_SPACE_DASH_LABEL.en
          }
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={ALIAS_LABEL.en}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
      <Controller
        name={`${SubstanceCategoryKey.KIT}.comments`}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label={COMMENTS_LABEL.en}
            {...field}
            margin="normal"
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default KitForm;
