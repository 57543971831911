import * as appBar from './trsl/appBar';
import * as batchRegistration from './trsl/batchRegistration';
import * as home from './trsl/home';
import * as landingPage from './trsl/landingPage';
import * as materialRegistration from './trsl/materialRegistration';
import * as modal from './trsl/modal';
import * as validation from './trsl/validation';
import * as formulation from './trsl/formulation';
import * as reagentForm from './trsl/reagentForm';

export default {
  home,
  landingPage,
  appBar,
  materialRegistration,
  batchRegistration,
  modal,
  validation,
  formulation,
  reagentForm
};
