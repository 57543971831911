import { i18n } from '../i18nType';

export const MATERIAL_REGISTRATION_CATEGORY: i18n = {
  en: 'Category'
};

export const CHANGE_NAME_TEXT: i18n = {
  en: 'Please edit details above and resubmit for a new substance or click Proceed to continue registering a batch.'
};

export const PROCEED_LABEL: i18n = {
  en: 'PROCEED'
};

export const SUBSTANCE_ALREADY_EXIST: i18n = {
  en: 'This substance already exists:'
};

export const NA_LABEL: i18n = {
  en: 'N/A'
};

export const SUBSTANCE_DETAILS_TITLE: i18n = {
  en: 'Substance Details'
};

export const BATCH_DETAILS_TITLE: i18n = {
  en: 'Batch Details'
};
