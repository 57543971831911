import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BatchDataResponse } from '../../../apis/batch/batchTypes';
import { getBatchFields } from '../../../apis/substance/getBatchFields';
import { BATCH_DETAILS_TITLE } from '../../../i18n/trsl/materialRegistration';
import BatchTable from '../SearchPage/BatchDetails/BatchTable';

export const BatchDetails = () => {
  const { batchId } = useParams();

  const [batchDetails, setBatchDetails] = useState<BatchDataResponse[]>([]);

  useEffect(() => {
    const getBatch = async () => {
      if (batchId) {
        const batchData = await getBatchFields({
          batchFields: [{ value: batchId, field: 'id' }]
        });
        setBatchDetails(batchData);
      }
    };
    getBatch();
  }, [batchId]);

  return (
    <Box m={4}>
      <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
        {BATCH_DETAILS_TITLE.en}: {batchId}
      </Typography>
      <BatchTable batches={batchDetails} />
    </Box>
  );
};
