import { i18n } from '../i18nType';

export const FOOTER_CONTACT: i18n = {
  en: 'Contact: ddit-red-nnrco@novonordisk.com'
};
export const FOOTER_CREDITS = {
  en: '© 2024 Novo Nordisk Research Center Oxford'
};
export const LOGIN_BUTTON: i18n = { en: 'Login' };
export const WELCOME_MESSAGE: i18n = {
  en: 'Login using the organisation SSO (Single Sign-On)!'
};
