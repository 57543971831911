import { SearchResultField } from '../all/searchResultsTypes';

export const buildQueryString = <T>(
  searchFields: SearchResultField<T>[]
): string => {
  let queryString = '';
  searchFields.forEach(searchField => {
    if (searchField.field && searchField.value) {
      const encodedValue = encodeURIComponent(searchField.value);
      if (queryString === '') {
        queryString = `${searchField.field}=${encodedValue}`;
      } else {
        queryString += `&${searchField.field}=${encodedValue}`;
      }
    }
  });
  return queryString;
};
