import { BatchFormFields } from '../../components/material-registration/types/batchFields';

export type ParsedBatch = BatchFormFields & { id: string };

export const parseBatchFields = (batch: any): ParsedBatch => {
  return {
    supplierBatchNumber: batch?.supplierBatchNumber,
    substanceId: batch?.substanceId,
    vendorSuppliedId: batch?.vendorSuppliedId,
    expirationDate: batch?.expirationDate,
    supplier: batch?.supplier,
    catalogueNumber: batch?.catalogueNumber,
    flag: batch?.flag,
    comments: batch?.comments,
    id: batch?.id
  };
};
