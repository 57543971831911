import { Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { searchFilters } from '../../../../data/searchFilters';
import AdvancedSearchWithFilters from './AdvancedSearchWithFilters';
import { FilterPair, SearchFilters } from './SearchFilters';

interface AdvancedSearchWrapperProps {
  /* eslint-disable no-unused-vars */
  handleSearch: (selectedFilters: Record<string, string>) => void;
}

const AdvancedSearchWrapper: FC<AdvancedSearchWrapperProps> = ({
  handleSearch
}) => {
  const [selectedFilters, setSelectedFilters] = useState<FilterPair[]>([]);

  const availableFilters = useMemo(
    () =>
      searchFilters.filter(searchFilter => {
        return !selectedFilters.some(selectedOption => {
          return selectedOption.filterType.value === searchFilter.value;
        });
      }),
    [selectedFilters, searchFilters]
  );

  useEffect(() => {
    const result = selectedFilters.reduce(
      (acc: Record<string, any>, filter) => {
        acc[filter.filterType.value] = filter.filterValue;
        return acc;
      },
      {}
    );
    handleSearch(result);
  }, [selectedFilters]);

  return (
    <Stack mt={2} maxWidth="70%" width="70%">
      <AdvancedSearchWithFilters
        selectedOptions={selectedFilters}
        handleSelectedOptions={setSelectedFilters}
      />
      <SearchFilters
        searchFilters={availableFilters}
        handleAddFilter={value =>
          setSelectedFilters(prevState => prevState.concat(value))
        }
      />
    </Stack>
  );
};

export default AdvancedSearchWrapper;
