import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { SubstanceDataResponse } from '../../../../apis/substance/substanceTypes';
import { LOADING, NO_RESULTS_LABEL } from '../../../../i18n/trsl/home';
import {
  NAME,
  NO_OF_BATCHES,
  SUBSTANCE_ACTIONS,
  TYPE
} from '../../../../i18n/trsl/search';
import LoadingSpinner from '../../../novo_components/LoadingSpinner/LoadingSpinner';
import { colors } from '../../../stylings/colors';
import PaginationStepper from './PaginationStepper';
import SubstanceTableRow from './SubstanceTableRow';

interface SubstanceTableProps {
  rows: SubstanceDataResponse[] | undefined;
  // eslint-disable-next-line no-unused-vars
  handleSelectedRow: (row: SubstanceDataResponse) => void;
  handleViewBatches: () => void;
  handleAddBatch: () => void;
  defaultExpanded?: boolean;
  hidePagination?: boolean;
}

export const SubstanceTable = ({
  rows,
  handleAddBatch,
  handleViewBatches,
  handleSelectedRow,
  defaultExpanded = false,
  hidePagination = false
}: SubstanceTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [rows]);

  const tableRows = useMemo(() => {
    switch (true) {
      case rows === undefined:
        return (
          <TableRow>
            <TableCell colSpan={5} align="center">
              <LoadingSpinner message={LOADING.en} />
            </TableCell>
          </TableRow>
        );
      case rows && rows.length === 0: {
        return (
          <TableRow>
            <TableCell colSpan={5} align="center">
              {NO_RESULTS_LABEL.en}
            </TableCell>
          </TableRow>
        );
      }
      default:
        return (
          rows &&
          rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((substance, idx) => (
              <SubstanceTableRow
                defaultExpanded={defaultExpanded && idx === 0}
                handleAddBatch={handleAddBatch}
                handleViewBatches={handleViewBatches}
                handleSelectedRow={handleSelectedRow}
                key={substance.id}
                row={substance}
              />
            ))
        );
    }
  }, [rows, page, rowsPerPage]);

  return (
    <Paper
      sx={{
        width: isMediumScreen ? '100%' : '80%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px'
      }}
    >
      <TableContainer
        sx={{
          paddingBottom: '30px'
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: colors.veryLightGray }}>
            <TableRow>
              <TableCell />
              <TableCell>{NAME.en}</TableCell>
              <TableCell>{TYPE.en}</TableCell>
              <TableCell align="center">{NO_OF_BATCHES.en}</TableCell>
              <TableCell align="center">{SUBSTANCE_ACTIONS.en}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
          {!hidePagination && (
            <TableFooter>
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={5}>
                  <PaginationStepper
                    isMediumScreen={isMediumScreen}
                    rowsLength={rows ? rows.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};
