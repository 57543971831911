import { InputAdornment, SxProps, TextField } from '@mui/material';
import { FC, ReactNode } from 'react';

interface TextBoxFilterProps {
  /* eslint-disable no-unused-vars */
  handleUpdate: (filterValue: string) => void;
  label: string;
  sx?: SxProps;
  icon?: ReactNode;
}

export const TextBoxFilter: FC<TextBoxFilterProps> = ({
  handleUpdate,
  label,
  icon,
  sx
}) => {
  return (
    <TextField
      sx={sx}
      onChange={event => handleUpdate(event?.target.value)}
      placeholder={label}
      InputProps={{
        startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
      }}
    />
  );
};
