import { Box, TablePagination } from '@mui/material';
import { ChangeEvent } from 'react';
import { colors } from '../../../stylings/colors';

interface PaginationStepperProps {
  rowsLength: number;
  page: number;
  rowsPerPage: number;
  isMediumScreen: boolean;
  /* eslint-disable no-unused-vars */
  handleChangePage: (_event: unknown, newPage: number) => void;
  /* eslint-disable no-unused-vars */
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
}

const PaginationStepper = ({
  isMediumScreen,
  page,
  rowsPerPage,
  rowsLength,
  handleChangePage,
  handleChangeRowsPerPage
}: PaginationStepperProps) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      position: 'fixed',
      bottom: 0,
      left: '50%',
      width: isMediumScreen ? '100%' : '80%',
      transform: 'translateX(-50%)',
      bgcolor: 'background.paper',
      borderTop: `1px solid ${colors.softGray}`,
      zIndex: 1100
    }}
  >
    <TablePagination
      component="div"
      rowsPerPageOptions={[5, 10, 20, 50]}
      count={rowsLength}
      rowsPerPage={rowsPerPage}
      page={page}
      onRowsPerPageChange={handleChangeRowsPerPage}
      slotProps={{
        select: {
          inputProps: { 'aria-label': 'rows per page' },
          native: true
        }
      }}
      onPageChange={handleChangePage}
      sx={{
        '& p': {
          margin: 0
        }
      }}
    />
  </Box>
);

export default PaginationStepper;
